import { styled, theme } from 'styles'
import { Text } from 'styles/global'

export const Element = styled.div`
  background: ${theme.color.grey};
  border-radius: 6px;
  padding: 5px 15px;
  display: flex;
  justify-content: center;
  margin: 10px;
`

export const JobDescription = styled.div`
  background: #f9f9f9;
  border-radius: 4px;
  padding: 10px;
  width: 100%;
  max-height: 150px;
  overflow-y: scroll;
`

export const UrgentLabel = styled(Text)`
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: ${theme.color.primary};
  color: white;
  font-weight: 600;
  font-size: 16px;
  padding: 8px 15px;
  margin-bottom: -35px;
  width: 150px;
  margin-top: 30px;
  text-align: center;
`