import React, { Dispatch, SetStateAction } from 'react'
import { theme } from 'styles'
import { Column, H3, Row, Text, Button } from 'styles/global'

import Img from 'assets/landings/candidat/img3.svg'
import { Img3, PartColumn } from '../style'
import { Link } from 'react-router-dom'
import { routes } from 'router'

const Part3 = ({ handleClickButton }: { handleClickButton: (e: any, path: string) => void }) => {
  return (
    <PartColumn id="title-2">
      <H3 margin="0 0 30px 0" width="100%" position="center" weight="600" size="24px">
        Le paysage actuel de l'emploi pour les pharmaciens en France
      </H3>
      <Row wrap justifyContent="space-between">
        <Column responsive="width: 100%;" width="60%">
          <Text margin="15px 0">
            La <strong>recherche d'emploi de pharmacien en France</strong> se situe dans un contexte
            professionnel et réglementaire très spécifique. Le pays compte près de 23 000 pharmacies
            d'officine et le secteur offre un large éventail de postes, tant en officine qu'en
            milieu hospitalier, dans l'industrie pharmaceutique ou dans la recherche. Les
            possibilités sont donc nombreuses pour les{' '}
            <strong>pharmaciens à la recherche d'un emploi</strong>.<br />
            <br /> Cependant, il est important de noter que le marché de l'emploi est également
            compétitif. Pour se démarquer, les pharmaciens doivent posséder un ensemble de
            compétences et de qualifications précises. Au-delà du diplôme d'État de Docteur en
            Pharmacie, il est souvent demandé aux candidats d'avoir des connaissances spécialisées,
            par exemple en biologie médicale ou en pharmacie industrielle.
            <br />
            <br /> Par ailleurs, le recrutement ne repose pas uniquement sur les compétences
            techniques : les qualités humaines sont tout aussi valorisées. Un bon pharmacien doit
            être à l'écoute de ses patients, faire preuve d'empathie, et avoir d'excellentes
            compétences en communication.
            <br />
            <br /> Pour réussir dans votre recherche d'emploi de pharmacien, il est donc essentiel
            de bien comprendre ce contexte et ces exigences. Notre plateforme, emploi-pharmacien,
            est là pour vous aider à naviguer dans ce paysage complexe.
          </Text>
          
          <Link to={routes.viewOffers.path}>
            <Button.Primary>Consulter les offres d’emploi</Button.Primary>
          </Link>
        </Column>
        <Img3 responsive="display: none;" src={Img} alt="domaine officine" />
      </Row>
    </PartColumn>
  )
}

export default Part3
