import React, { Dispatch, SetStateAction } from 'react'
import { Column, H3, H2, Row, Text, Button } from 'styles/global'

import Img from 'assets/landings/candidat/img10.svg'
import { Img2, PartColumn } from '../style'
import { routes } from 'router'
import { Link } from 'react-router-dom'

const Part10 = ({ handleClickButton }: { handleClickButton: (e: any, path: string) => void }) => {
  return (
    <PartColumn blue id="title-7">
      <Row width="100%" wrap justifyContent="space-between">
        <Column responsive="display: none" width="30%">
          <Img2 style={{ width: '100%', marginBottom: '20px' }} src={Img} alt="secteur officine" />
        </Column>
        <Column responsive="width: 100%;" width="60%">
          <H3 margin="0 0 30px 0" width="100%" position="start" weight="500" size="24px">
            Comment rester à jour sur les nouvelles offres d'emploi sur Emploi-Pharmacien ?
          </H3>
          <Text margin="15px 0">
            La meilleure façon de rester à jour est de vous inscrire à nos alertes emploi. Vous
            recevrez ainsi les nouvelles offres correspondant à vos critères directement par mail.
            De plus, consultez régulièrement notre plateforme pour découvrir les dernières offres
            d'emploi mises en ligne.
          </Text>
        </Column>
      </Row>
      <Row width="100%" justifyContent="center">
        <Link to={routes.viewOffers.path}>
          <Button.Primary margin="20px 0 0 0" minWidth="650px" responsive="min-width: auto;">
            Consulter les offres d’emploi
          </Button.Primary>
        </Link>
      </Row>
    </PartColumn>
  )
}

export default Part10
