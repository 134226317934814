import React, { Dispatch, SetStateAction } from 'react'
import { Column, H3, Row, Text, Button } from 'styles/global'

import Img from 'assets/landings/candidat/img9.svg'
import { Img2, PartColumn } from '../style'
import { routes } from 'router'

const Part11 = ({ handleClickButton }: { handleClickButton: (e: any, path: string) => void }) => {
  return (
    <PartColumn blue id="title-8">
      <Row wrap width="100%" justifyContent="space-between">
        <Column responsive="width: 100%;" width="60%">
          <H3 margin="0 0 30px 0" width="100%" position="start" weight="500" size="24px">
            Comment rédiger un CV adapté au secteur pharmaceutique ?
          </H3>
          <Text margin="15px 0">
            Votre CV doit mettre en avant vos compétences et votre expérience dans le domaine de la
            pharmacie. N'oubliez pas d'inclure vos diplômes, vos stages et vos formations
            complémentaires. Mentionnez également vos spécialisations, si vous en avez, et tout
            autre élément pertinent comme une expérience à l'étranger ou des compétences en
            management.
          </Text>
        </Column>
        <Column responsive="width: 100%;" width="30%">
          <Img2 style={{ width: '100%', marginBottom: '20px' }} src={Img} alt="interface simple" />
        </Column>
      </Row>
    </PartColumn>
  )
}

export default Part11
