import React, { Dispatch, SetStateAction } from 'react'
import { Column, H2, Row, Text, Button } from 'styles/global'

import Img from 'assets/landings/candidat/img2.svg'
import { Img3, PartColumn } from '../style'
import { routes } from 'router'
import { Link } from 'react-router-dom'

const Part2 = ({ handleClickButton }: { handleClickButton: (e: any, path: string) => void }) => {
  return (
    <PartColumn id="title-1">
      <H2 margin="0 0 30px 0" width="100%" position="center" weight="600" size="24px">
        Une plateforme dédiée à l'emploi en officine
      </H2>
      <Row wrap justifyContent="space-between">
        <Img3 src={Img} alt="plateforme emploi pharmacien" />
        <Column responsive="width: 100%;" width="60%">
          <Text margin="15px 0">
            Dans le monde professionnel de la santé, en constante évolution, le métier de pharmacien
            joue un rôle central et indispensable. En tant que pilier de la communauté médicale, le
            pharmacien a pour mission de contribuer à la santé publique, de dispenser des
            médicaments de manière sûre et efficace et d'offrir des conseils précieux aux patients.
            <br />
            <br />
            En ce sens, la <strong>recherche d'emploi de pharmacien</strong> est un enjeu crucial
            pour les professionnels de la santé. Que vous soyez fraîchement diplômé à la recherche
            de votre premier emploi ou un pharmacien expérimenté souhaitant relever de nouveaux
            défis, la recherche d'un poste adapté à vos compétences et ambitions est une étape clé
            dans votre carrière. Dans cette perspective,{' '}
            <Link to={routes.home.path}>emploi-pharmacien </Link> vous propose un guide complet pour
            faciliter votre <strong>recherche d'emploi dans le secteur de la pharmacie</strong>.
          </Text>
          <Link to={routes.viewOffers.path}>
            <Button.Primary>Consulter les offres d’emploi</Button.Primary>
          </Link>
        </Column>
      </Row>
    </PartColumn>
  )
}

export default Part2
