import React from 'react'
import { Column, H2, Row, Text } from 'styles/global'

import Img from 'assets/landings/company/img5.svg'
import { Img3, PartColumn } from '../style'

const Part5 = () => {
  return (
    <PartColumn id="title-4">
      <Row wrap width="100%" justifyContent="space-between">
        <Column responsive="width: 100%" width="60%">
          <H2 margin="0 0 30px 0" width="100%" position="start" weight="600" size="24px">
            Comment optimiser le processus de recrutement de pharmaciens ?
          </H2>
          <Text margin="15px 0">
            Optimiser le <strong>processus de recrutement de pharmaciens</strong> passe en premier
            lieu par la rédaction d'une annonce attractive et efficace. Celle-ci doit présenter de
            manière claire et précise le poste à pourvoir, le profil recherché ainsi que les
            avantages offerts par l'entreprise. Elle doit également mettre en avant les valeurs de
            l'entreprise et donner envie aux candidats de postuler. Pour cela, il est essentiel de
            soigner le titre de l'annonce et de personnaliser le message en fonction du profil de
            pharmacien recherché. <br />
            <br /> Vient ensuite l'évaluation des candidats, étape tout aussi cruciale. Il s'agit
            d'identifier les compétences techniques du professionnel ainsi que ses qualités humaines
            et relationnelles, essentielles dans le métier de pharmacien. Pour cela, préparez
            soigneusement vos questions et n'hésitez pas à proposer des mises en situation pour
            évaluer concrètement les compétences du candidat. Enfin, gardez à l'esprit que
            l'entretien est également l'occasion pour le postulant d'évaluer votre entreprise, alors
            soyez transparent et accueillant.
            <br />
            <br /> En somme, optimiser le recrutement de pharmaciens demande une préparation
            minutieuse et une bonne compréhension du profil des candidats.
          </Text>
        </Column>
        <Img3 src={Img} alt="annonce emploi" />
      </Row>
    </PartColumn>
  )
}

export default Part5
