import React, { useState } from 'react'
import { Helmet } from 'react-helmet'

import { Column } from 'styles/global'
import { PartRow } from './style'
import { Anchor } from 'components'
import { anchorItems } from './anchorItems'
import {
  Part1,
  Part2,
  Part3,
  Part4,
  Part5,
  Part6,
  Part7,
  Part8,
  Part9,
  Part10,
  Part11,
  Part12,
} from './components'
import Login from 'components/login'
import { authUtils } from 'utils'
import { useNavigate } from 'react-router-dom'
import { routes } from 'router'

const CompanyHome = () => {
  const [loginRedirection, setLoginRedirection] = useState<string>('')
  const [defaultLoginEmail, setDefaultLoginEmail] = useState<string>('')
  const navigate = useNavigate()

  const handleClickButton = (e, path) => {
    if (authUtils.isAuth() || path === routes.contact.path) {
      navigate(path === 'default' ? routes.myOffers.path : path)
    } else {
      e.preventDefault()
      e.stopPropagation()
      setLoginRedirection(path)
    }
  }

  return (
    <Column margin="0 0 -80px 0" style={{ background: 'white' }} width="100%" alignItems="center">
      <Helmet>
        <title>Recrutement de pharmaciens qualifiés - spécialiste du marché pharmaceutique</title>
        <meta
          name="description"
          content=" Emploi-pharmacien, votre partenaire pour le recrutement de pharmaciens hautement qualifiés. Notre plateforme répond aux spécificités du marché du recrutement en pharmacie et aide à trouver les professionnels qualifiés."
        />
        <meta name="keywords" content="pharmacien, emploi, recrutement" />
      </Helmet>
      {loginRedirection && (
        <Login
          defaultEmail={defaultLoginEmail}
          isDisplayed={loginRedirection}
          setIsDisplayed={setLoginRedirection}
          isRegistration={true}
        />
      )}
      <Part1 handleClickButton={handleClickButton} setDefaultLoginEmail={setDefaultLoginEmail} />
      <PartRow>
        <Anchor items={anchorItems} />
      </PartRow>
      <Part2 handleClickButton={handleClickButton} />
      <Part3 handleClickButton={handleClickButton} />
      <Part4 handleClickButton={handleClickButton} />
      <Part5 />
      <Part6 handleClickButton={handleClickButton} />
      <Part7 />
      <Part8 handleClickButton={handleClickButton} />
      <Part9 handleClickButton={handleClickButton} />
      <Part10 handleClickButton={handleClickButton} />
      <Part11 handleClickButton={handleClickButton} />
      <Part12 />
    </Column>
  )
}

export default CompanyHome
