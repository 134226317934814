import { InputButton } from 'components'
import React, { Dispatch, SetStateAction, useState } from 'react'
import { theme } from 'styles'
import { Column, H1, Row, Text } from 'styles/global'

import Img from 'assets/landings/company/img1.svg'
import { PartRow, Img1 } from '../style'
import { PromotionInformationsModal } from 'components'

const Part1 = ({
  handleClickButton,
  setDefaultLoginEmail,
}: {
  handleClickButton: (e: any, path: string) => void
  setDefaultLoginEmail: Dispatch<SetStateAction<string>>
}) => {
  const [isPromotionModalDisplayed, setIsPromotionModalDisplayed] = useState<boolean>(false)
  return (
    <PartRow wrap justifyContent="space-between" blue>
      {isPromotionModalDisplayed && (
        <PromotionInformationsModal setIsDisplayed={setIsPromotionModalDisplayed} />
      )}
      <Column responsive="width: 95%" width="50%">
        <H1 weight="500" size="22px">
          Recrutement pharmacien
        </H1>
        <Text margin="15px 0">
          Découvrez la principale interface dédiée au recrutement dans le domaine officine,
          emploi-pharmacien.fr. Avec notre audience hautement spécialisée, chaque proposition de
          poste attire l'attention de centaines de professionnels de santé engagés.
        </Text>
        <InputButton
          callback={(value, e) => {
            setDefaultLoginEmail(value)
            handleClickButton(e, 'default')
          }}
          buttonContent="Publier une offre d'emploi"
          placeholder="Adresse email"
        />
        <Text margin="15px 0 0 0" weight="300">
          Inscrivez vous gratuitement afin d’accéder aux profils de pharmaciens et publier vos
          offres d’emploi
        </Text>
        <Text
          onClick={() => setIsPromotionModalDisplayed(true)}
          pointer
          underline
          margin="25px 0 0px 0"
          color="blue"
          weight="600"
          size="14px"
        >
          Découvrir comment nous promouvons vos offres d'emploi
        </Text>
      </Column>
      <Img1 src={Img} alt="recrutement pharmacien" />
    </PartRow>
  )
}

export default Part1
