import React, { useContext, useState } from 'react'
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'
import { paymentServices, userService } from 'services'
import { UserContext } from 'providers'
import { message } from 'antd'
import { Button, Text } from 'styles/global'
import { Loader } from 'components'
import { routes } from 'router'

const CARD_ELEMENT_OPTIONS = {
  style: {
    width: '100%',
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
}

interface Props {
  price: number
  credits?: number
  successCallback: () => void
}

export default function CheckoutForm({ price, credits, successCallback }: Props) {
  const [loading, setLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const stripe = useStripe()
  const elements = useElements()

  const { user } = useContext(UserContext)

  const handleFormSubmit = async (event) => {
    const handleError = () => {
      message.error('Une erreur est survenue, veuillez réessayer ou nous contacter')
      setLoading(false)
      setIsError(true)
      return
    }
    event.preventDefault()
    setLoading(true)

    const paymentIntent = await paymentServices.createIntent({ price, email: user.email })
    if (!paymentIntent.clientSecret) {
      return handleError()
    }

    // @ts-ignore
    const stripeResult = await stripe.confirmCardPayment(paymentIntent.clientSecret, {
      payment_method: {
        // @ts-ignore
        card: elements.getElement(CardElement),
      },
    })
    if (stripeResult?.paymentIntent?.status !== 'succeeded') {
      return handleError()
    }

    const payment = await paymentServices.createPayment({
      stripePaymentId: stripeResult?.paymentIntent?.id || '',
      userId: user._id,
      cost: price,
      credits: credits || 0,
    })

    if (!payment._id) {
      return handleError()
    }

    const updateUserCredits = await userService.updateCredits({ paymentId: payment._id })
    if (updateUserCredits.status !== 200) {
      return handleError()
    }

    successCallback()

    setLoading(false)
  }

  const handleContact = () => {
    window.open(
      routes.contact.path + '?message=Je rencontre un problème de paiement',
      '_blank', // ceci ouvre un nouvel onglet
    )
  }

  return (
    <form style={{ width: '100%' }} onSubmit={handleFormSubmit}>
      {loading && <Loader isDisplayed={loading} />}
      <div
        style={{
          border: '1px solid rgb(170 170 189)',
          borderRadius: '6px',
          padding: '15px 20px',
          width: '100%',
        }}
      >
        <CardElement options={CARD_ELEMENT_OPTIONS} />
      </div>
      {isError && (
        <Text margin="5px 0 0 0" color="blue" underline pointer onClick={handleContact}>
          Une problème avec le paiement ? Nous contacter
        </Text>
      )}
      <Button.Primary width="100%" margin="25px 0 0 0" type="submit" disabled={!stripe || loading}>
        Confirmer
      </Button.Primary>
    </form>
  )
}
