import React, { Dispatch, SetStateAction } from 'react'
import { H3, Row, Text, Button } from 'styles/global'

import { PartColumn } from '../style'
import { routes } from 'router'

const Part6 = ({ handleClickButton }: { handleClickButton: (e: any, path: string) => void }) => {
  return (
    <PartColumn id="title-5">
      <H3 margin="0 0 30px 0" width="100%" position="center" weight="600" size="24px">
        L'importance de l'accompagnement dans le recrutement de pharmaciens
      </H3>
      <Text margin="15px 0">
        Le recrutement de pharmaciens est une tâche exigeante et qui nécessite une expertise
        spécifique. Pour réussir cette mission, un accompagnement de qualité est souvent la clé. Cet
        accompagnement permet de cibler plus efficacement les candidats potentiels, de gagner du
        temps dans le processus de recrutement. <br />
        <br /> De plus, un bon accompagnement peut également aider à fidéliser les talents recrutés.
        En effet, un processus de recrutement bien géré est un gage de sérieux et de
        professionnalisme qui participe à l'image de l'entreprise et à l'engagement des
        collaborateurs.
        <br />
        <br /> C'est pour répondre à ces enjeux qu’emploi-pharmacien propose un accompagnement sur
        mesure pour le recrutement de pharmaciens. En plus de la publication d'offres d'emploi, nous
        mettons à votre disposition une <strong>base de données de CV de pharmaciens</strong> et
        nous vous aidons à cibler et à évaluer les candidats. Notre objectif est de vous offrir les
        meilleures ressources pour réussir votre recrutement.
      </Text>
      <Row wrap margin="30px 0 0 0" width="100%" justifyContent="space-evenly">
        <Button.Primary onClick={(e) => handleClickButton(e, 'default')}>
          Publier une offre d’emploi
        </Button.Primary>
        <Button.Third
          responsive="margin-top: 30px;"
          onClick={(e) => handleClickButton(e, routes.contact.path)}
        >
          Nous contacter
        </Button.Third>
      </Row>
    </PartColumn>
  )
}

export default Part6
