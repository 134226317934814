import React, { useState } from 'react'
import { Helmet } from 'react-helmet'

import { Column } from 'styles/global'
import { PartRow } from './style'
import { Anchor } from 'components'
import { anchorItems } from './anchorItems'
import {
  Part1,
  Part2,
  Part3,
  Part4,
  Part5,
  Part6,
  Part7,
  Part8,
  Part9,
  Part10,
  Part11,
  Part12,
  Part13,
} from './components'
import Login from 'components/login'
import { authUtils } from 'utils'
import { useNavigate } from 'react-router-dom'
import { routes } from 'router'

const CandidatHome = () => {
  const [loginRedirection, setLoginRedirection] = useState<string>('')
  const [defaultLoginEmail, setDefaultLoginEmail] = useState<string>('')
  const navigate = useNavigate()

  const handleClickButton = (e, path) => {
    if (authUtils.isAuth() || path === routes.contact.path) {
      navigate(path === 'default' ? routes.myOffers.path : path)
    } else {
      e.preventDefault()
      e.stopPropagation()
      setLoginRedirection(path)
    }
  }

  return (
    <Column style={{ background: 'white', marginBottom: '-80px' }} width="100%" alignItems="center">
    <Helmet>
      <title>Plateforme de recherche d'emploi de pharmacien en France </title>
      <meta
        name="description"
        content="Trouvez l'emploi idéal dans le secteur de la pharmacie en France. Que vous soyez un jeune diplômé ou un professionnel expérimenté, nous vous aidons à naviguer dans le paysage complexe et concurrentiel du marché de l'emploi en pharmacie."
      />
      <meta name="keywords" content="pharmacien, emploi, recrutement" />
    </Helmet>
      {loginRedirection && (
        <Login
          defaultEmail={defaultLoginEmail}
          isDisplayed={loginRedirection}
          setIsDisplayed={setLoginRedirection}
          isRegistration={true}
        />
      )}
      <Part1 handleClickButton={handleClickButton} setDefaultLoginEmail={setDefaultLoginEmail} />
      <PartRow>
        <Anchor items={anchorItems} />
      </PartRow>
      <Part2 handleClickButton={handleClickButton} />
      <Part3 handleClickButton={handleClickButton} />
      <Part4 handleClickButton={handleClickButton} />
      <Part5 handleClickButton={handleClickButton} />
      <Part6 handleClickButton={handleClickButton} />
      <Part7 />
      <Part8 handleClickButton={handleClickButton} />
      <Part9 handleClickButton={handleClickButton} />
      <Part10 handleClickButton={handleClickButton} />
      <Part11 handleClickButton={handleClickButton} />
      <Part12 handleClickButton={handleClickButton} />
      <Part13 />
    </Column>
  )
}

export default CandidatHome
