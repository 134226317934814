import React from 'react'
import { H3, Text } from 'styles/global'

import { PartColumn } from '../style'
import { routes } from 'router'
import { Link } from 'react-router-dom'
import { theme } from 'styles'

const Part10 = () => {
  return (
    <PartColumn id="title-11">
      <H3 margin="0 0 30px 0" width="100%" position="center" weight="600" size="24px">
        Le recrutement de pharmaciens avec emploi-pharmacien
      </H3>
      <Text margin="15px 0">
        Le recrutement de pharmaciens est un défi majeur pour les structures sanitaires et les
        entreprises du secteur pharmaceutique. Il requiert une bonne compréhension du{' '}
        <strong>marché de l'emploi en pharmacie</strong>, l'adoption de stratégies adaptées et une
        optimisation constante du processus de recrutement. Un accompagnement spécialisé est un
        atout précieux dans cette démarche.
        <br />
        <br /> Vous êtes à la <strong>recherche d’un pharmacien</strong> ? Emploi-pharmacien
        s'impose comme un partenaire de choix pour vous aider à relever ce défi. Notre plateforme
        offre des services dédiés pour faciliter le recrutement de pharmaciens.
        <br />
        <br /> N'attendez plus, franchissez la prochaine étape dans votre démarche de{' '}
        <strong>recrutement de pharmaciens qualifiés</strong> avec emploi-pharmacien ! <br />
        <br />
        Vous êtes un professionnel à la recherche d’un emploi ? Découvrez notre page spécialement
        dédiée à la recherche d’
        <Link
          style={{ textDecoration: 'underline', color: theme.color.blue }}
          to={routes.viewOffers.path}
        >
          emploi de pharmacien
        </Link>
        <br />
        <br /> Pour plus d’informations concernant le recrutement dans le secteur pharmaceutique, la
        rédaction d’offres d’emploi optimisées ou les tendances du marché de l’emploi, n’hésitez pas
        à consulter notre{' '}
        <a style={{ textDecoration: 'underline', color: theme.color.blue }} href="https://blog.emploi-pharmacien.fr">
          Blog spécialisé
        </a>
      </Text>
    </PartColumn>
  )
}

export default Part10
