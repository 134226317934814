import React, { Dispatch, SetStateAction, useState } from 'react'
import { InputButton } from 'components'
import { Button, Column, H2, H3, Row, Text } from 'styles/global'

import Img from 'assets/landings/company/img1.svg'
import img2 from '../assets/company/img1.svg'
import img3 from '../assets/company/img3.svg'
import img4 from '../assets/company/img2.svg'
import img5 from '../assets/company/img4.svg'
import { PartRow, Img1, Img2, Img3 } from '../style'
import { routes } from 'router'
import { Link } from 'react-router-dom'
import { theme } from 'styles'
import { PartColumn } from 'pages/companyHome/style'
import { PromotionInformationsModal } from 'components'

const CompanyPart = ({
  handleClickButton,
  setDefaultLoginEmail,
}: {
  handleClickButton: (e: any, path: string) => void
  setDefaultLoginEmail: Dispatch<SetStateAction<string>>
}) => {
  const [isPromotionModalDisplayed, setIsPromotionModalDisplayed] = useState<boolean>(false)
  return (
    <PartColumn blue width="100%">
      {isPromotionModalDisplayed && (
        <PromotionInformationsModal setIsDisplayed={setIsPromotionModalDisplayed} />
      )}
      <Row margin="0 0 30px 0" width="100%" wrap justifyContent="space-between">
        <Column responsive="width: 95%" width="50%">
          <H2 weight="500" size="22px">
            Recrutement pharmaciens : des candidats compétents
          </H2>
          <Text margin="15px 0 15px 0" weight="300">
            Inscrivez vous gratuitement afin d’accéder aux profils pharmaciens et publier vos offres
            d’emploi
          </Text>
          <InputButton
            callback={(value, e) => {
              setDefaultLoginEmail(value)
              handleClickButton(e, 'default')
            }}
            buttonContent="Poster une offre d'emploi"
            placeholder="Adresse email"
          />
          <Text
            onClick={() => setIsPromotionModalDisplayed(true)}
            pointer
            underline
            margin="15px 0 0px 0"
            color="blue"
            weight="500"
            size="14px"
          >
            Découvrir comment nous promouvons vos offres d'emploi
          </Text>
        </Column>
        <Img1 src={Img} alt="Recruter en officine" />
      </Row>

      <Text margin="30px 0 0 0">
        Dans un monde où le secteur de la santé prend une place de plus en plus prépondérante, le{' '}
        <Link
          style={{ color: theme.color.blue, textDecoration: 'underline' }}
          to={routes.companyHome.path}
        >
          recrutement de pharmaciens compétents
        </Link>{' '}
        est devenu un enjeu crucial pour les entreprises pharmaceutiques. Emploi-pharmacien offre
        ainsi une solution idéale et adaptée pour les acteurs (pharmacies de ville, hôpitaux,
        centres de recherche…) en quête de professionnels talentueux et qualifiés. Notre mission est
        de faciliter votre <strong>recrutement dans le secteur pharmaceutique</strong>.
      </Text>
      <H3 margin="50px 0 30px 0" width="100%" position="start" weight="600" size="24px">
        Une plateforme de recrutement adaptée
      </H3>
      <Text>
        Sur emploi-pharmacien, votre plateforme en ligne spécialisée, nous simplifions la{' '}
        <strong>procédure de recrutement de pharmaciens</strong>. Nous vous proposons une gamme de
        services variée pour combler efficacement vos besoins en ressources humaines. Accès à une
        base de données conséquente de <strong>CV de pharmaciens</strong>, publication de vos offres
        d'emploi sur notre site, assistance en direct… chaque outil est pensé pour optimiser votre
        recherche.
      </Text>
      <Column margin="50px 0" id="title-3">
        <Row width="100%" wrap justifyContent="space-between">
          <Column responsive="width: 100%;" width="65%">
            <H3 margin="0 0 30px 0" width="100%" position="start" weight="600" size="24px">
              L'efficacité au service du recrutement de pharmacien
            </H3>
            <Text margin="15px 0">
              Emploi-pharmacien est bien plus qu'un site de mise en relation. Nous sommes un
              partenaire privilégié qui vous accompagne tout au long du processus de recrutement.
              Avec une audience composée de plusieurs centaines de pharmaciens, nous vous offrons
              une visibilité unique pour toucher les candidats les plus qualifiés pour les besoins
              de votre structure. Ainsi, nous œuvrons chaque jour à mettre en relation les
              recruteurs avec des <strong>professionnels du secteur pharmaceutique</strong>{' '}
              compétents et passionnés.
            </Text>

            <Button.Primary margin="10px 0 0 0" onClick={(e) => handleClickButton(e, 'default')}>
              Publier une offre d'emploi
            </Button.Primary>
          </Column>
          <Column responsive="width: 100%; margin-bottom: 20px;" width="30%" maxWidth="600px">
            <Img3
              style={{ width: '100%', marginBottom: '20px' }}
              responsive="width: calc(100% - 10vw) !important;"
              src={img5}
              alt="Discussion pharmacien"
            />
          </Column>
        </Row>
      </Column>
      <Column margin="50px 0" id="title-3">
        <Row width="100%" wrap justifyContent="space-between">
          <Column responsive="width: 100%; margin-bottom: 20px;" width="30%" maxWidth="600px">
            <Img2
              style={{ width: '100%', marginBottom: '20px' }}
              responsive="width: calc(100% - 10vw) !important;"
              src={img3}
              alt="interface saas"
            />
          </Column>
          <Column responsive="width: 100%;" width="65%">
            <H3 margin="0 0 30px 0" width="100%" position="start" weight="600" size="24px">
              Emploi-pharmacien, votre partenaire pour un recrutement de pharmacien
            </H3>
            <Text margin="15px 0">
              Emploi-pharmacien ne se contente pas de lister des offres d'emploi. La plateforme vous
              offre également la possibilité de parcourir les pages dédiées aux{' '}
              <strong>entreprises du secteur pharmaceutique actuellement en recrutement</strong>.
              Ainsi, vous disposez de toutes les informations nécessaires pour vous informer de
              manière détaillée et ciblée avant de soumettre votre candidature. Cette approche vous
              permet de comprendre précisément le cadre dans lequel vous envisagez de travailler et
              de vous y adapter au mieux.
            </Text>

            <Button.Primary
              width="250px"
              margin="10px 0 0 0"
              onClick={(e) => handleClickButton(e, routes.contact.path)}
            >
              Nous contacter
            </Button.Primary>
          </Column>
        </Row>
      </Column>

      <Column margin="50px 0 20px 0" id="title-3">
        <Row width="100%" wrap justifyContent="space-between">
          <Column responsive="width: 100%;" width="65%">
            <H3 margin="0 0 30px 0" width="100%" position="start" weight="600" size="24px">
              Votre démarche de recrutement de pharmacien
            </H3>
            <Text margin="15px 0">
              Avec emploi-pharmacien, vous faites le choix de l'excellence et de l'efficacité pour
              le <strong>recrutement de vos pharmaciens</strong>. En choisissant de nous faire
              confiance, vous optez pour une plateforme dédiée, en prise directe avec une audience
              qualifiée. Profitez de notre expertise pour recruter les pharmaciens qui sauront
              répondre à vos exigences et s'intégrer à vos équipes.
              <br />
              <br /> Avec emploi-pharmacien, le recrutement de pharmaciens n'a jamais été aussi
              simple et efficace. Votre recherche du candidat idéal devient notre mission. Ainsi,
              ensemble, nous contribuons à l'épanouissement du secteur pharmaceutique en France.
              Nous nous engageons à vous offrir un service de recrutement professionnel, rapide et
              sur mesure, car nous savons que la réussite de votre entreprise repose sur les talents
              que vous recrutez.
            </Text>
          </Column>
          <Column responsive="width: 100%; margin-bottom: 20px;" width="30%" maxWidth="600px">
            <Img2
              style={{ width: '100%', marginBottom: '20px' }}
              responsive="width: calc(100% - 10vw) !important;"
              src={img2}
              alt="interface saas"
            />
          </Column>
        </Row>
      </Column>
      <H3 margin="0 0 30px 0" width="100%" position="start" weight="600" size="24px">
        Emploi-pharmacien, votre partenaire pour un recrutement de pharmacien
      </H3>
      <Text margin="0 0 30px 0">
        Nous vous invitons à découvrir nos services et à profiter de l'opportunité de trouver le{' '}
        <strong>professionnel de la pharmacie</strong> que vous recherchez. Emploi-pharmacien, c'est
        la garantie d'un recrutement de pharmacien efficace et pertinent, à l'image de vos
        ambitions. Alors n'attendez plus, franchissez la prochaine étape dans votre démarche de
        recrutement. Nous sommes impatients de vous accompagner dans cette aventure passionnante et
        enrichissante.
      </Text>
    </PartColumn>
  )
}

export default CompanyPart
