import baseStyled, { ThemedStyledInterface } from 'styled-components'

import {
  Input as antdInput,
  Form as antdForm,
  Drawer as antdDrawer,
  Select as antdSelect,
  Collapse as antdCollapse,
  Table as antdTable,
} from 'antd'
const { TextArea: antdTextArea } = antdInput

import { theme, mediaQuery } from 'styles'

const styled = baseStyled as ThemedStyledInterface

interface CSS {
  justifyContent: string
  alignItems: string
  width: string
  height: string
  weight: string
  color: string
  size: string
  margin: string
  position: string
  pointer: boolean
  display: boolean
  fontSize: string
  wrap: boolean
  responsive: any
  padding: string
  ellipsis: boolean
  grey: boolean
  maxWidth: string
  isStyled: boolean
  boxShadow: string
  underline: boolean
  minWidth: string
}

export const Row = styled.div`
  display: flex;
  justify-content: ${({ justifyContent }: CSS) => justifyContent || 'flex-start'};
  align-items: ${({ alignItems }: CSS) => alignItems || 'flex-start'};
  margin: ${({ margin }: CSS) => margin || '0'};
  flex-wrap: ${({ wrap }: CSS) => wrap && 'wrap'};
  width: ${({ width }: CSS) => width || ''};
  max-width: ${({ maxWidth }: CSS) => maxWidth || ''};
  min-width: ${({ minWidth }: CSS) => minWidth || ''};
  cursor: ${({ pointer }: CSS) => pointer && 'pointer'};
  ${mediaQuery.mobile} {
    ${({ responsive }: CSS) => responsive};
  }
`

export const Column = styled(Row)`
  flex-direction: column;
`

export const ColumnCenter = styled(Column)`
  justify-content: center;
  align-items: center;
`

export const Title = styled.h1`
  font-weight: ${({ weight }: CSS) =>
    weight === '' ? theme.font.weight.medium : theme.font.weight[weight] || weight};
  color: ${({ color }: CSS) =>
    color ? theme.font.color[color] || theme.color[color] : theme.font.color.primary};
  font-size: ${({ size }: CSS) => (size ? size : theme.font.size.title)};
  margin: ${({ margin }: CSS) => margin || '0'};
  text-align: ${({ position }: CSS) => position || 'center'};
  width: ${({ width }: CSS) => width || ''};
  max-width: ${({ maxWidth }: CSS) => maxWidth || ''};
  ${({ ellipsis }: CSS) =>
    ellipsis &&
    `
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  `};
  ${mediaQuery.mobile} {
    ${({ responsive }: CSS) => responsive};
  }
`

export const Text = styled.p`
  font-weight: ${({ weight }: CSS) =>
    weight === '' ? theme.font.weight.medium : theme.font.weight[weight] || weight};
  color: ${({ color }: CSS) =>
    color ? theme.font.color[color] || theme.color[color] || color : theme.font.color.primary};
  font-size: ${({ size }: CSS) => (size ? size : theme.font.size.primary)};
  margin: ${({ margin }: CSS) => margin || '0'};
  text-align: ${({ position }: CSS) => position || 'start'};
  cursor: ${({ pointer }: CSS) => pointer && 'pointer'};
  width: ${({ width }: CSS) => width};
  width: ${({ width }: CSS) => width || ''};
  max-width: ${({ maxWidth }: CSS) => maxWidth || ''};
  ${({ underline }: CSS) =>
    underline &&
    `
  text-decoration: underline;
  `};
  ${({ ellipsis }: CSS) =>
    ellipsis &&
    `
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  `};
  ${mediaQuery.mobile} {
    ${({ responsive }: CSS) => responsive};
  }
`

export const H1 = styled.h1`
  font-weight: ${({ weight }: CSS) =>
    weight === '' ? theme.font.weight.medium : theme.font.weight[weight] || weight};
  color: ${({ color }: CSS) =>
    color ? theme.font.color[color] || theme.color[color] : theme.font.color.primary};
  font-size: ${({ size }: CSS) => (size ? size : theme.font.size.primary)};
  margin: ${({ margin }: CSS) => margin || '0'};
  text-align: ${({ position }: CSS) => position || 'start'};
  cursor: ${({ pointer }: CSS) => pointer && 'pointer'};
  width: ${({ width }: CSS) => width || ''};
  max-width: ${({ maxWidth }: CSS) => maxWidth || ''};
  ${({ underline }: CSS) =>
    underline &&
    `
  text-decoration: underline;
  `};
  ${({ ellipsis }: CSS) =>
    ellipsis &&
    `
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  `};
  ${mediaQuery.mobile} {
    ${({ responsive }: CSS) => responsive};
  }
`

export const H2 = styled.h2`
  font-weight: ${({ weight }: CSS) =>
    weight === '' ? theme.font.weight.medium : theme.font.weight[weight] || weight};
  color: ${({ color }: CSS) =>
    color ? theme.font.color[color] || theme.color[color] : theme.font.color.primary};
  font-size: ${({ size }: CSS) => (size ? size : theme.font.size.primary)};
  margin: ${({ margin }: CSS) => margin || '0'};
  text-align: ${({ position }: CSS) => position || 'start'};
  cursor: ${({ pointer }: CSS) => pointer && 'pointer'};
  width: ${({ width }: CSS) => width || ''};
  max-width: ${({ maxWidth }: CSS) => maxWidth || ''};
  ${({ underline }: CSS) =>
    underline &&
    `
  text-decoration: underline;
  `};
  ${({ ellipsis }: CSS) =>
    ellipsis &&
    `
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  `};
  ${mediaQuery.mobile} {
    ${({ responsive }: CSS) => responsive};
  }
`

export const H3 = styled.h3`
  font-weight: ${({ weight }: CSS) =>
    weight === '' ? theme.font.weight.medium : theme.font.weight[weight] || weight};
  color: ${({ color }: CSS) =>
    color ? theme.font.color[color] || theme.color[color] : theme.font.color.primary};
  font-size: ${({ size }: CSS) => (size ? size : theme.font.size.primary)};
  margin: ${({ margin }: CSS) => margin || '0'};
  text-align: ${({ position }: CSS) => position || 'start'};
  cursor: ${({ pointer }: CSS) => pointer && 'pointer'};
  width: ${({ width }: CSS) => width || ''};
  max-width: ${({ maxWidth }: CSS) => maxWidth || ''};
  ${({ underline }: CSS) =>
    underline &&
    `
  text-decoration: underline;
  `};
  ${({ ellipsis }: CSS) =>
    ellipsis &&
    `
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  `};
  ${mediaQuery.mobile} {
    ${({ responsive }: CSS) => responsive};
  }
`

export const H4 = styled.h4`
  font-weight: ${({ weight }: CSS) =>
    weight === '' ? theme.font.weight.medium : theme.font.weight[weight] || weight};
  color: ${({ color }: CSS) =>
    color ? theme.font.color[color] || theme.color[color] : theme.font.color.primary};
  font-size: ${({ size }: CSS) => (size ? size : theme.font.size.primary)};
  margin: ${({ margin }: CSS) => margin || '0'};
  text-align: ${({ position }: CSS) => position || 'start'};
  cursor: ${({ pointer }: CSS) => pointer && 'pointer'};
  width: ${({ width }: CSS) => width || ''};
  max-width: ${({ maxWidth }: CSS) => maxWidth || ''};
  ${({ underline }: CSS) =>
    underline &&
    `
  text-decoration: underline;
  `};
  ${({ ellipsis }: CSS) =>
    ellipsis &&
    `
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  `};
  ${mediaQuery.mobile} {
    ${({ responsive }: CSS) => responsive};
  }
`

const defaultInput = `
background-color: white;
border-radius: 6px;
height: 45px;
border: 1px solid ${theme.color.grey};
${mediaQuery.mobile} {
  max-width: 95vw;
}
`

export const Input = styled(antdInput)`
  ${defaultInput}
  width: ${({ width }: CSS) => width || '280px'};
  color: ${theme.color.black};
  border-radius: 4px;
  height: 35px;
`

export const InputPassword = styled(antdInput.Password)`
  ${defaultInput}
  width: ${({ width }: CSS) => width || '280px'};
  color: ${theme.color.primary};
`

export const TextArea = styled(antdTextArea)`
  ${defaultInput}
  height: ${({ height }: CSS) => (height || '150px') + '!important'};
  width: ${({ width }: CSS) => width || '450px'};
  color: ${theme.color.black};
  ${mediaQuery.mobile} {
    max-width: 95vw;
    width: ${({ width }: CSS) => width || '450px'};
  }
`

export const Select = styled(antdSelect)`
  width: ${({ width }: CSS) => width || '280px'};
  border: none;
  border-radius: 4px;
  height: 35px;
  border: 1px solid ${theme.color.grey};
  ${mediaQuery.mobile} {
    max-width: 95vw;
    ${({ responsive }: CSS) => responsive};
  }
`

export const Form = styled(antdForm)`
  flex-direction: column;
  margin: ${({ margin }: CSS) => margin || ''};
  width: ${({ width }: CSS) => width || ''};
  ${({ isStyled }: CSS) =>
    isStyled &&
    `
  padding: 30px;
  border: 1px solid ${theme.color.grey};
  border-radius: 4px;
  background-color: white;
  `}
  ${mediaQuery.mobile} {
    ${({ responsive }: CSS) => responsive};
  }
`

export const Collapse = styled(antdCollapse)`
  background-color: white;
  width: ${({ width }: CSS) => width || '200px'};
`

export const defaultButton = `
border-radius: 18px;
border: none;
display: flex;
justify-content: center;
align-items: center;
font-weight: 500;
position: relative;
cursor: pointer;
`

export const Button = {
  Primary: styled.button`
    ${defaultButton}
    color: ${theme.color.white};
    a {
      color: white;
    }
    border-radius: 9px;
    background-color: ${theme.color.secondary};
    font-size: ${({ fontSize }: CSS) => fontSize || '16px'};
    margin: ${({ margin }: CSS) => margin || '0'};
    width: ${({ width }: CSS) => width || ''};
    height: ${({ height }: CSS) => height || ''};
    padding: ${({ padding }: CSS) => padding || '10px 25px'};
    font-weight: ${({ weight }: CSS) => weight || ''};
    ${mediaQuery.mobile} {
      ${({ responsive }: CSS) => responsive};
    }
  `,
  Special: styled.button`
    ${defaultButton}
    color: white;
    a {
      color: white;
    }
    background-color: transparent;
    font-size: ${({ fontSize }: CSS) => fontSize || '16px'};
    border: 2px solid white;
    width: ${({ width }: CSS) => width || ''};
    height: ${({ height }: CSS) => height || ''};
    padding: ${({ padding }: CSS) => padding || '8px 23px'};
    margin: ${({ margin }: CSS) => margin || '0'};
    font-weight: ${({ weight }: CSS) => weight || ''};
    ${mediaQuery.mobile} {
      ${({ responsive }: CSS) => responsive};
    }
  `,
  Secondary: styled.button`
    ${defaultButton}
    color: ${theme.color.secondary};
    a {
      color: ${theme.color.secondary};
    }
    background-color: white;
    font-size: ${({ fontSize }: CSS) => fontSize || '16px'};
    width: ${({ width }: CSS) => width || ''};
    height: ${({ height }: CSS) => height || ''};
    padding: ${({ padding }: CSS) => padding || '10px 25px'};
    margin: ${({ margin }: CSS) => margin || '0'};
    box-shadow: ${({ boxShadow }: CSS) => boxShadow || ''};
    font-weight: ${({ weight }: CSS) => weight || ''};
    ${mediaQuery.mobile} {
      ${({ responsive }: CSS) => responsive};
    }
  `,
  Third: styled.button`
    ${defaultButton}
    color: ${theme.color.secondary};
    a {
      color: ${theme.color.secondary};
    }
    border: 2px solid ${theme.color.secondary};
    border-radius: 9px;
    background-color: white;
    font-size: ${({ fontSize }: CSS) => fontSize || '16px'};
    width: ${({ width }: CSS) => width || ''};
    height: ${({ height }: CSS) => height || ''};
    padding: ${({ padding }: CSS) => padding || '10px 25px'};
    margin: ${({ margin }: CSS) => margin || '0'};
    box-shadow: ${({ boxShadow }: CSS) => boxShadow || ''};
    font-weight: ${({ weight }: CSS) => weight || ''};
    ${mediaQuery.mobile} {
      ${({ responsive }: CSS) => responsive};
    }
  `,
  Neumorphism: styled.button`
  ${defaultButton}
  color: ${theme.color.black};
  position: relative;
  a {
    color: ${theme.color.black};
  }
  border: none;
  border-radius: 9px;
  box-shadow: 0.5px 0.5px 2px #b5b5b5;
  background-color: white;
  font-size: ${({ fontSize }: CSS) => fontSize || '15px'};
  width: ${({ width }: CSS) => width || ''};
  height: ${({ height }: CSS) => height || ''};
  padding: ${({ padding }: CSS) => padding || '10px 25px'};
  margin: ${({ margin }: CSS) => margin || '0'};
  font-weight: ${({ weight }: CSS) => weight || ''};
  ${mediaQuery.mobile} {
    ${({ responsive }: CSS) => responsive};
  }
`
}

export const Display = styled.div`
  display: ${({ display }: CSS) => (display ? 'block' : 'none')};
`

export const Drawer = styled(antdDrawer)`
  padding: 0;
`

export const Table = styled(antdTable)`
  width: 100%;
  ${mediaQuery.mobile} {
    padding: 0;
    ${({ responsive }: CSS) => responsive};
  }
`
export const Card = styled.div`
  box-shadow: ${theme.shadow};
  background: white;
  border-radius: 6px;
  padding: ${({ padding }: CSS) => padding || '25px'};
  width: ${({ width }: CSS) => width || ''};
  height: ${({ height }: CSS) => height || ''};
  margin: ${({ margin }: CSS) => margin || '0'};
  ${mediaQuery.mobile} {
    ${({ responsive }: CSS) => responsive};
  }
`
