import React, { useEffect, useState, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Divider } from 'antd'
import { Helmet } from 'react-helmet'

import {
  Container,
  Banner,
  Card,
  Back1,
  CardTitle,
  Back2,
  Back3,
  Back4,
  ServiceImg,
  SalesIllustration,
} from './style'
import { Row, Button, Text, H1, H2, H3, H4 } from 'styles/global'
import { routes } from 'router'
import { windowSizes } from 'styles/mediaQuery'
import { UserContext } from 'providers'
import { authUtils } from 'utils'
import Login from 'components/login'
import { ACCOUNT_TYPES } from 'constants/index'
import { JobOffersHome, PromotionInformationsModal } from 'components'

import cvThequeImg from 'assets/illustrations/cvTheque.png'
import publishImg from 'assets/illustrations/publish.png'
import salesIllustration from 'assets/illustrations/sales_illustration_2.svg'

import CandidatPart from './components/CandidatePart'
import CompanyPart from './components/CompanyPart'

const Home = () => {
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < windowSizes.mobile)
  const [isLoginFormDisplayed, setIsLoginFormDisplayed] = useState<string>('')
  const [isPromotionModalDisplayed, setIsPromotionModalDisplayed] = useState<boolean>(false)
  const [defaultLoginEmail, setDefaultLoginEmail] = useState<string>('')
  const [loginRedirection, setLoginRedirection] = useState<string>('')
  const navigate = useNavigate()

  const isConnected: boolean = authUtils.isAuth()

  useEffect(() => {
    window.addEventListener('resize', () =>
      window.innerWidth < windowSizes.mobile ? setIsMobile(true) : setIsMobile(false),
    )
    return () =>
      window.removeEventListener('resize', () =>
        window.innerWidth < windowSizes.mobile ? setIsMobile(true) : setIsMobile(false),
      )
  }, [])

  const handleCandidatRedirect = (e) => {
    if (!isConnected) {
      e.stopPropagation()
      e.preventDefault()
      setIsLoginFormDisplayed(routes.candidateAccount.path)
    }
  }

  const handleClickButton = (e, path) => {
    if (authUtils.isAuth() || path === routes.contact.path) {
      navigate(path === 'default' ? routes.myOffers.path : path)
    } else {
      e.preventDefault()
      e.stopPropagation()
      setLoginRedirection(path)
    }
  }

  const handleScrollToServices = () => {
    document.getElementById('recruteurs')?.scrollIntoView({
      behavior: 'smooth',
    })
  }

  return (
    <Container margin="0 0 -80px 0">
      {isPromotionModalDisplayed && (
        <PromotionInformationsModal setIsDisplayed={setIsPromotionModalDisplayed} />
      )}
      {loginRedirection && (
        <Login
          isRegistration={true}
          isDisplayed={loginRedirection}
          setIsDisplayed={setLoginRedirection}
          defaultEmail={defaultLoginEmail}
        />
      )}
      <Helmet>
        <title>Recrutement et offres d'emploi pour professionnels du secteur pharmaceutique</title>
        <meta
          name="description"
          content="Découvrez emploi-pharmacien, votre plateforme d'opportunités professionnelles pour pharmaciens et recruteurs. Rejoignez-nous pour booster votre carrière ou recruter efficacement dans le domaine pharmaceutique."
        />
        <meta name="keywords" content="pharmacien, emploi, recrutement" />
        <script type="application/ld+json">
          {/* BIEN MODIFIER LE NOM DU SITE */}
          {`{"@context": "http://schema.org","@type": "WebSite","name" : "Emploi-Pharmacien","url": "https://www.emploi-pharmacien.fr"}`}
        </script>
        <script type="application/ld+json">
          {`{"@context" : "http://schema.org", "@type" : "Organization","name" : "Emploi-Pharmacien","url" : "https://www.emploi-pharmacien.fr","logo": "https://emploi-pharmacien.fr/static/media/logo.2bbcfa7a173e57ac7e78.png"}`}
        </script>
      </Helmet>
      <Banner>
        <H1 margin="0 0 20px 0" style={{ maxWidth: '90vw' }} size="40px" weight="500" color="white">
          Le portail emploi <span style={{ fontWeight: 'bold' }}>pharmacien</span>
          <br />
          <span style={{ fontSize: '32px' }}>Pour les entreprises et pour les candidats</span>
        </H1>
        {authUtils.currentRole() !== ACCOUNT_TYPES.CANDIDATE && (
          <Button.Secondary
            onClick={() => setIsPromotionModalDisplayed(true)}
            weight="600"
            fontSize="18px"
            margin="20px 0 25px 0"
          >
            Services aux recruteurs
          </Button.Secondary>
        )}
        <Row>
          <Button.Secondary weight="600" fontSize="18px" margin="0 20px 0 0">
            <Link to={routes.viewOffers.path}>Offres d’emploi</Link>
          </Button.Secondary>
          {authUtils.currentRole() !== ACCOUNT_TYPES.COMPANY && (
            <Button.Secondary onClick={handleCandidatRedirect} weight="600" fontSize="18px">
              {isConnected ? (
                <Link to={routes.candidateAccount.path}>Modifier mon profil</Link>
              ) : (
                'Déposer mon CV'
              )}
            </Button.Secondary>
          )}
        </Row>
      </Banner>
      <JobOffersHome />

      <CompanyPart
        setDefaultLoginEmail={setDefaultLoginEmail}
        handleClickButton={handleClickButton}
      />
      <CandidatPart
        setDefaultLoginEmail={setDefaultLoginEmail}
        handleClickButton={handleClickButton}
      />
    </Container>
  )
}

export default Home
