import React, { Dispatch, SetStateAction } from 'react'
import { Column, H3, H2, Row, Text, Button } from 'styles/global'

import Img from 'assets/landings/company/img6.svg'
import { Img2, PartColumn } from '../style'

const Part8 = ({ handleClickButton }: { handleClickButton: (e: any, path: string) => void }) => {
  return (
    <PartColumn blue id="title-7">
    <H3 margin="0 0 80px 0" width="100%" position="center" weight="600" size="30px">
      Questions fréquentes
    </H3>
      <Row width="100%" wrap justifyContent="space-between">
        <Column responsive="width: 100%; margin-bottom: 30px;" width="30%">
          <Img2
            style={{ width: '100%', marginBottom: '20px' }}
            src={Img}
            alt="outils recrutement"
          />
          <Button.Third width="100%" onClick={(e) => handleClickButton(e, 'default')}>
            Découvrir nos outils
          </Button.Third>
        </Column>
        <Column responsive="width: 100%;" width="60%">
          <H2 margin="0 0 30px 0" width="100%" position="start" weight="500" size="24px">
            Quels sont les avantages de recruter un pharmacien par le biais d'une plateforme
            spécialisée comme emploi-pharmacien ?
          </H2>
          <Text margin="15px 0">
            Recruter un pharmacien via une plateforme spécialisée comme emploi-pharmacien offre
            plusieurs avantages. En premier lieu, vous bénéficiez d'une visibilité accrue auprès
            d'une audience qualifiée et ciblée, ce qui augmente vos chances de trouver le candidat
            idéal. De plus, nous mettons à votre disposition une base de données de CV de
            pharmaciens, ce qui vous permet de gagner du temps en ciblant directement les profils
            qui correspondent à vos critères. Enfin, notre service d'accompagnement sur mesure vous
            aide à optimiser votre processus de recrutement, de la rédaction de l'annonce à la
            sélection des candidats.
          </Text>
        </Column>
      </Row>
    </PartColumn>
  )
}

export default Part8
