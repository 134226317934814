import React, { useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'

import { Form, Input, Title, TextArea, Button } from 'styles/global'
import { ContactForm } from 'types'
import { globalServices } from 'services'
import { message } from 'antd'
import { urlParams } from 'utils'

const ContactFormComponent = () => {
  const paramsUrl = urlParams()

  const defaultContactForm = {
    name: '',
    email: '',
    phone: '',
    message: paramsUrl.message || '',
  }

  const [contactForm, setContactForm] = useState<ContactForm>(defaultContactForm)
  const [isRefresh, setIsRefresh] = useState<boolean>(false)
  const [isCaptchaValid, setIsCaptchaValid] = useState<boolean>(false)

  const onFinish = () => {
    globalServices.contact(contactForm).then((res) => {
      if (res.status === 200) {
        message.success('Votre message vient de nous être envoyé, merci !')
        setIsCaptchaValid(false)
        setContactForm(defaultContactForm)
        setIsRefresh(!isRefresh)
      } else {
        message.error('Une erreur est survenue, veuillez nous contacter via un autre canal')
      }
    })
  }

  const handleChange = (attr, e) => {
    const newValues: ContactForm = { ...contactForm }
    newValues[attr] = e.target.value
    setContactForm(newValues)
  }

  return (
    <Form
      key={isRefresh}
      responsive="width: 95vw; max-width: 450px;"
      width="450px"
      style={{ marginTop: '40px' }}
      layout="vertical"
      onFinish={onFinish}
      isStyled
    >
      <Title margin="0 0 30px 0" color="secondary">
        Nous contacter
      </Title>
      <Form.Item
        name="name"
        onChange={(e) => handleChange('name', e)}
        label="Nom"
        rules={[{ required: true, message: 'Veuillez renseigner votre nom' }]}
      >
        <Input width="100%" />
      </Form.Item>
      <Form.Item
        name="email"
        onChange={(e) => handleChange('email', e)}
        label="Adresse email"
        rules={[{ required: true, message: 'Veuillez renseigner votre adresse email' }]}
      >
        <Input width="100%" />
      </Form.Item>
      <Form.Item
        name="Numéro de téléphone"
        onChange={(e) => handleChange('phone', e)}
        label="Téléphone"
        rules={[{ required: true, message: 'Veuillez renseigner votre numéro de téléphone' }]}
      >
        <Input width="100%" />
      </Form.Item>
      <Form.Item
        name="Message"
        onChange={(e) => handleChange('message', e)}
        label="message"
        
        rules={[{ required: false }]}
      >
        <TextArea defaultValue={contactForm.message} width="100%" />
      </Form.Item>
      {/*
      <ReCAPTCHA sitekey="Your client site key" onChange={(value) => setIsCaptchaValid(value)} />*/}
      <Form.Item>
        <Button.Primary width="100%" type="primary" htmlType="submit">
          Envoyer
        </Button.Primary>
      </Form.Item>
    </Form>
  )
}

export default ContactFormComponent
