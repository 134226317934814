import { styled } from 'styles'
import { Column } from 'styles/global'

export const Container = styled(Column)`
  width: 100vw;
  min-height: 100vh;
  align-items: center;
`

export const PromotionIllustration = styled.img`
  width: 100%;
`

export const InfoIcon = styled.img`
  margin-bottom: 8px;
  margin-left: 10px;
  height: 18px;
  cursor: pointer;
`