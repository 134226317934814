import React from 'react'
import { H2, Text } from 'styles/global'

import { PartColumn } from '../style'
import { routes } from 'router'
import { Link } from 'react-router-dom'
import { theme } from 'styles'

const Part13 = () => {
  return (
    <PartColumn id="title-26">
      <H2 margin="0 0 30px 0" width="100%" position="center" weight="600" size="24px">
        Opportunités et défis dans la recherche d'un emploi pharmacien
      </H2>
      <Text margin="15px 0">
        La <strong>recherche d'emploi de pharmacien</strong> peut être un chemin semé d'opportunités
        mais aussi de défis. Le secteur pharmaceutique en France est riche et diversifié, offrant
        une multitude de postes et de perspectives de carrière. Cependant, dans ce marché
        concurrentiel, il est important de savoir se démarquer.
        <br />
        <br /> De fait, la différence se fait sur votre capacité à mettre en valeur vos compétences,
        à rédiger un CV attractif et une lettre de motivation convaincante, à utiliser efficacement
        les outils à votre disposition. Vous êtes à la recherche d’une{' '}
        <strong>offre de préparateur en pharmacie</strong> ou de pharmacien d’officine ? Chez
        emploi-pharmacien, nous mettons à jour constamment nos offres d'emploi et nous vous
        proposons des ressources pour vous aider à optimiser votre candidature. <br />
        <br />
        Rappelez-vous : chaque <strong>emploi de pharmacien</strong> est une opportunité de
        contribuer à la santé et au bien-être de la population. Bonne recherche et bonne chance !{' '}
        <br />
        <br />
        Vous êtes un cabinet ou un recruteur ? Découvrez notre page spécialement dédiée au{' '}
        <Link
          style={{ color: theme.color.blue, textDecoration: 'underline' }}
          to={routes.companyHome.path}
        >
          {' '}
          recrutement de pharmaciens
        </Link>{' '}
        <br />
        <br />
        Pour plus d’informations concernant le recrutement dans le secteur pharmaceutique, la
        rédaction de CV optimisés ou les tendances du marché de l’emploi, n’hésitez pas à consulter
        notre{' '}
        <a
          style={{ color: theme.color.blue, textDecoration: 'underline' }}
          href="https://blog.emploi-pharmacien.fr/blog"
        >
          Blog spécialisé
        </a>
        .
      </Text>
    </PartColumn>
  )
}

export default Part13
