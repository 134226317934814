import React, { useEffect, useState } from 'react'
import { JobOffer } from 'types'
import { convertFromRaw } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'

import { JobOfferCard, Element, Icon, DescribeContent } from './style'
import { Text } from 'styles/global'

import locationIcon from 'assets/icons/location.png'
import salaryIcon from 'assets/icons/salary.png'
import contractIcon from 'assets/icons/contract.png'
import { routes } from 'router'
import { Link, useNavigate } from 'react-router-dom'
import { parseSalary } from 'utils'

const JobOfferCardHome = ({ jobOffer }: { jobOffer: JobOffer }) => {
  const navigate = useNavigate()
  const [htmlDescription, setHtmlDescription] = useState<string>('')
  useEffect(() => {
    try {
      const content = convertFromRaw(JSON.parse(jobOffer.description))
      //@ts-ignore
      setHtmlDescription(stateToHTML(content))
    } catch {}
  }, [])

  return (
    <Link style={{ margin: '20px' }} to={`${routes.jobOffer.path}?id=${jobOffer._id}`}>
      <JobOfferCard>
        <Text style={{ color: '#ff8e8e' }} weight="300" color="grey" margin="0 0 7px 0">
          Nouveau
        </Text>
        <Text width="100%" ellipsis size="16px" weight="600">
          {jobOffer.title}
        </Text>
        {jobOffer.userId ? (
          <Link
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              navigate(`/profil-entreprise?companyId=${jobOffer.userId}`)
            }}
            to={`/profil-entreprise?companyId=${jobOffer.userId}`}
          >
            <Text size="14px" underline weight="400" margin="5px 0 15px 0">
              {jobOffer.officeName}
            </Text>
          </Link>
        ) : (
          <Text size="14px" color="blue" underline weight="400" margin="5px 0 15px 0">
            {jobOffer.officeName}
          </Text>
        )}
        <Element>
          <Icon alt="salaire" src={salaryIcon} />
          <Text margin="0 5px 0 7px" weight="bold">
            {parseSalary({ salary: jobOffer.salary })}
          </Text>
        </Element>
        <Element>
          <Icon alt="localisation" src={locationIcon} />
          <Text margin="0 5px 0 7px" weight="bold">
            {jobOffer.address.city && jobOffer.address.city + ', '}
            {jobOffer.address.region && jobOffer.address.region + ', '}
            {jobOffer.address.postalCode}
          </Text>
        </Element>
        <Element>
          <Icon alt="type de contrat" src={contractIcon} />
          <Text margin="0 5px 0 7px" weight="bold">
            {jobOffer.contractType}
          </Text>
        </Element>
        {htmlDescription ? (
          <DescribeContent dangerouslySetInnerHTML={{ __html: htmlDescription }} ellispsis={true} />
        ) : (
          jobOffer.description && <DescribeContent>{jobOffer.description}</DescribeContent>
        )}
      </JobOfferCard>
    </Link>
  )
}

export default JobOfferCardHome
