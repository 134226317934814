import React, { Dispatch, SetStateAction, useContext, useState } from 'react'
import { Checkbox, Divider } from 'antd'
import JobOfferCard from 'components/jobOffer'
import { Column, Row, Title, Text, Button, Card } from 'styles/global'
import { JobOffer, Upsell } from 'types'
import { UserContext } from 'providers'

import { BuyCredits } from 'components'
import { InfoIcon } from '../style'

import infoImg from 'assets/icons/info.png'
import GoogleForJobs from './infoModals/GoogleForJobs'
import Multicanal from './infoModals/Multicanal'
import Banner from './infoModals/Banner'
import BackTop from './infoModals/BackTop'

type UpsellPrice = {
  backTop: {
    active: boolean
    cost: number
  }
  colorBand: {
    active: boolean
    cost: number
  }
}

interface Props {
  jobOffer: JobOffer
  setJobOffer: Dispatch<SetStateAction<JobOffer>>
  onFinish: ({ upsell, creditsCost }: { upsell: Upsell; creditsCost: number }) => void
  setIsDisplayed: Dispatch<SetStateAction<boolean>>
}

const Confirmation = ({ jobOffer, setJobOffer, onFinish, setIsDisplayed }: Props) => {
  const { user } = useContext(UserContext)
  const [isBuyCreditsDisplayed, setIsBuyCreditsDisplayed] = useState<boolean>(false)
  const [upsell, setUpsell] = useState<UpsellPrice>({
    backTop: {
      active: false,
      cost: 4,
    },
    colorBand: {
      active: false,
      cost: 2,
    },
  })

  const calcTotalCost = (): number => {
    let cost = 10
    if (upsell.backTop.active) {
      cost += upsell.backTop.cost
    }
    if (upsell.colorBand.active) {
      cost += upsell.colorBand.cost
    }

    return cost
  }

  return (
    <Column width="100%" alignItems="center">
      <Title margin="40px 0 20px 0">Résumé de votre offre d'emploi</Title>
      <Card margin="30px 0 50px 0" responsive="width: 95vw" padding="25px 35px" width="70vw">
        {isBuyCreditsDisplayed && <BuyCredits setIsDisplayed={setIsBuyCreditsDisplayed} />}

        <Row justifyContent="space-between" width="100%">
          <Text size="16px" weight="500">
            Publication de l'offre d'emploi pendant 30 jours :
          </Text>
          <Text margin="0 0 0 30px" size="15px" weight="600">
            10 crédits
          </Text>
        </Row>
        <Row margin="20px 0 0 0" justifyContent="space-between" width="100%">
          <Checkbox
            onChange={() =>
              setJobOffer({ ...jobOffer, editorialAssistance: !jobOffer.editorialAssistance })
            }
            checked={jobOffer.editorialAssistance}
          >
            <Row>
              <Text weight="500">
                Aide à la rédaction du contenu de votre offre d'emploi par un recruteur expérimenté
                :
              </Text>
            </Row>
          </Checkbox>
          <Text margin="0 0 0 30px" weight="600">
            Gratuit
          </Text>
        </Row>
        <Row margin="20px 0 0 0" justifyContent="space-between" width="100%">
          <Checkbox checked={true}>
            <Row>
              <Text style={{ maxWidth: '100%' }} weight="500">
                Promotion de votre offre d'emploi par email auprès de +400 professionnels qualifiés
                dans la zone géographique :
              </Text>
            </Row>
          </Checkbox>
          <Text margin="0 0 0 30px" weight="600">
            Gratuit
          </Text>
        </Row>
        <Row margin="20px 0 0 0" justifyContent="space-between" width="100%">
          <Checkbox checked={true}>
            <Row>
              <Text weight="500">Référencement sur Google for Jobs :</Text>

              <GoogleForJobs />
            </Row>
          </Checkbox>
          <Text margin="0 0 0 30px" weight="600">
            Gratuit
          </Text>
        </Row>
        <Row margin="20px 0 0 0" justifyContent="space-between" width="100%">
          <Checkbox checked={true}>
            <Row>
              <Text weight="500">Publication multicanale sur +10 jobs boards généralistes :</Text>

              <Multicanal />
            </Row>
          </Checkbox>
          <Text margin="0 0 0 30px" weight="600">
            Gratuit
          </Text>
        </Row>
        <Row margin="20px 0 0 0" justifyContent="space-between" width="100%">
          <Checkbox
            onChange={() =>
              setUpsell({
                ...upsell,
                colorBand: { ...upsell.colorBand, active: !upsell.colorBand.active },
              })
            }
            checked={upsell.colorBand.active}
          >
            <Row>
              <Text weight={upsell.colorBand.active ? '500' : '400'}>Bandeau de couleur :</Text>

              <Banner />
            </Row>
          </Checkbox>
          <Text position="end" margin="0 0 0 30px" weight="600">
            {upsell.colorBand.cost} crédits
          </Text>
        </Row>
        <Row margin="20px 0 0 0" justifyContent="space-between" width="100%">
          <Checkbox
            onChange={() =>
              setUpsell({
                ...upsell,
                backTop: { ...upsell.backTop, active: !upsell.backTop.active },
              })
            }
            checked={upsell.backTop.active}
          >
            <Row>
              <Text weight={upsell.backTop.active ? '500' : '400'}>
                Remettre l’offre en top après deux semaines :
              </Text>
              <BackTop />
            </Row>
          </Checkbox>
          <Text position="end" margin="0 0 0 30px" weight="600">
            {upsell.backTop.cost} crédits
          </Text>
        </Row>

        <Divider />

        <Row margin="20px 0 0 0" justifyContent="space-between" width="100%">
          <Text size="20px" weight="500">
            Total :
          </Text>
          <Text position="end" margin="0 0 0 30px" size="18px" weight="600">
            {calcTotalCost()} crédits
          </Text>
        </Row>
        <Row margin="10px 0 30px 0" justifyContent="space-between" width="100%">
          <Text size="15px" weight="400">
            Crédits restants après publication :
          </Text>
          <Text
            color={(user.credits || 0) - calcTotalCost() < 0 && 'red'}
            margin="0 0 0 30px"
            size="15px"
            weight="400"
            position="end"
          >
            {(user.credits || 0) - calcTotalCost()} crédits
          </Text>
        </Row>
        <Row responsive="flex-direction: column-reverse;" margin="50px 0 0 0" width="100%" justifyContent="space-between">
          <Button.Third responsive="margin-top: 20px;" padding="6px 15px" fontSize="14px" onClick={() => setIsDisplayed(false)}>
            Retour à l'édition
          </Button.Third>
          {!user.credits || user.credits < calcTotalCost() ? (
            <Button.Primary width="280px" onClick={() => setIsBuyCreditsDisplayed(true)}>
              Acheter des crédits
            </Button.Primary>
          ) : (
            <Button.Primary
              width="280px"
              onClick={() =>
                onFinish({
                  upsell: {
                    backTop: {
                      active: upsell.backTop.active,
                    },
                    colorBand: {
                      active: upsell.colorBand.active,
                    },
                  },
                  creditsCost: calcTotalCost(),
                })
              }
            >
              Poster mon offre d'emploi
            </Button.Primary>
          )}
        </Row>
      </Card>

      <JobOfferCard jobOffer={jobOffer} />
    </Column>
  )
}

export default Confirmation
