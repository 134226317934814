import { Modal } from 'antd'
import React, { useState } from 'react'
import { Column, Text } from 'styles/global'
import { InfoIcon } from '../../style'

import infoImg from 'assets/icons/info.png'

import img from '../assets/google_for_jobs.png'

const GoogleForJobs = () => {
  const [isDisplayed, setIsDisplayed] = useState<boolean>(false)
  return (
    <>
      <InfoIcon onClick={() => setIsDisplayed(true)} src={infoImg} alt="info" />

      <Modal footer={[]} visible={isDisplayed} onCancel={(e) => {
        e.preventDefault()
        e.stopPropagation()
        setIsDisplayed(false)
      }}>
        <Column width="100%" alignItems="center">
          <Text margin="0 0 20px 0">
            <b>Google for Jobs est une nouvelle fonctionnalité de Google</b> qui vise à mettre en
            avant leurs outils de référencement d'offre d'emploi en premier résultat d'une recherche
            Google
          </Text>
          <Text>
            Nous faisons notre maximum afin que chaque offre d'emploi publié sur
            pharmacien-emploi.fr réponde aux normes imposées par Google afin de référencer votre
            offre d'emploi sur cet outil
          </Text>
          <img src={img} alt="illustration" style={{ width: '100%', marginTop: '30px' }} />
        </Column>
      </Modal>
    </>
  )
}

export default GoogleForJobs
