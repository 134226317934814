import React from 'react'
import { Button, H2, Row, Text } from 'styles/global'

import { PartColumn } from '../style'

const Part7 = () => {
  return (
    <PartColumn id="title-6">
      <H2 margin="0 0 30px 0" width="100%" position="center" weight="600" size="24px">
        Comment préparer sa candidature pour un emploi pharmacien
      </H2>
      <Text margin="15px 0">
        Lors de votre <strong>recherche d'emploi de pharmacien</strong>, la préparation de votre
        candidature est une étape cruciale. Voici quelques conseils pour optimiser vos chances de
        succès.
      </Text>
      <ol>
        <li style={{ marginBottom: '10px' }}>
          CV attractif : Le CV est votre carte de visite. Il doit être clair, précis et mettant en
          valeur vos compétences, votre expérience et vos diplômes. Pensez à indiquer vos stages,
          vos formations complémentaires et vos éventuelles spécialisations. Assurez-vous que la
          mise en page soit soignée et professionnelle, et que l'orthographe soit irréprochable.
        </li>
        <li>
          Lettre de motivation convaincante : Votre lettre de motivation doit compléter votre CV en
          expliquant pourquoi vous êtes le candidat idéal pour le poste. Mettez en avant vos
          motivations, votre connaissance du secteur pharmaceutique et ce que vous pouvez apporter à
          l'entreprise. Il s'agit également d'une excellente occasion de montrer que vous avez bien
          compris les exigences du poste.
        </li>
      </ol>
      <Text margin="15px 0">
        Une candidature bien préparée peut faire la différence lors d'une recherche d'emploi de
        pharmacien. Sur emploi-pharmacien, nous vous offrons des ressources et des conseils pour
        vous aider à préparer une candidature qui retiendra l'attention des recruteurs.
      </Text>
      <Row margin="30px 0 0 0" width="100%" justifyContent="center">
        <a target="_blank" rel="noreferrer" href="https://blog.emploi-pharmacien.fr">
          <Button.Third minWidth="450px" responsive="margin-top: 30px;">
            Consulter notre blog destiné aux pharmaciens
          </Button.Third>
        </a>
      </Row>
    </PartColumn>
  )
}

export default Part7
