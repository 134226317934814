import React, { Dispatch, SetStateAction } from 'react'
import { Column, H3, Row, Text, Button } from 'styles/global'

import Img from 'assets/landings/company/img9.svg'
import { Img2, PartColumn } from '../style'
import { routes } from 'router'

const Part9 = ({ handleClickButton }: { handleClickButton: (e: any, path: string) => void }) => {
  return (
    <PartColumn blue id="title-8">
      <Row wrap width="100%" justifyContent="space-between">
        <Column responsive="width: 100%;" width="60%">
          <H3 margin="0 0 30px 0" width="100%" position="start" weight="500" size="24px">
            Quelle est l'importance de l'accompagnement dans le recrutement de pharmaciens ?
          </H3>
          <Text margin="15px 0">
            Un accompagnement de qualité dans le recrutement de pharmaciens permet de cibler plus
            efficacement les candidats et de gagner du temps. Chez emploi-pharmacien, nous vous
            accompagnons à chaque étape du processus de recrutement pour vous aider à trouver le
            candidat idéal.
          </Text>
        </Column>
        <Column responsive="width: 100%;" width="30%">
          <Img2 style={{ width: '100%', marginBottom: '20px' }} src={Img} alt="cvThèque" />
        </Column>
      </Row>
    </PartColumn>
  )
}

export default Part9
