import React, { Dispatch, SetStateAction } from 'react'
import { Column, H3, Row, Text, Button } from 'styles/global'

import Img from 'assets/landings/candidat/img7.svg'
import { Img2, PartColumn } from '../style'
import { routes } from 'router'

const Part9 = ({ handleClickButton }: { handleClickButton: (e: any, path: string) => void }) => {
  return (
    <PartColumn blue id="title-8">
      <Row wrap width="100%" justifyContent="space-between">
        <Column responsive="width: 100%;" width="60%">
          <H3 margin="0 0 30px 0" width="100%" position="start" weight="500" size="24px">
            Comment maximiser mes chances d'être recruté ?
          </H3>
          <Text margin="15px 0">
            Pour maximiser vos chances, soyez actif dans votre recherche et réactif dans vos
            réponses aux offres. Préparez un CV précis, clair et attrayant, et une lettre de
            motivation personnalisée qui démontre votre intérêt et votre connaissance de
            l'entreprise.
          </Text>
        </Column>
        <Column responsive="width: 100%;" width="30%">
          <Img2 style={{ width: '100%', marginBottom: '20px' }} src={Img} alt="interface simple" />
        </Column>
      </Row>
    </PartColumn>
  )
}

export default Part9
