import { styled, theme } from 'styles'
import { Column, Form as FormP } from 'styles/global'

export const Form = styled(FormP)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
  padding: 0;
`

export const ClicGif = styled.img`
  width: 150px;
  position: absolute;
  left: calc(50% + 30px)
`

export const Illustration = styled.img`
  width: 300px;
  margin-bottom: 50px;
`
