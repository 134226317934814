import React, { Dispatch, SetStateAction } from 'react'
import { theme } from 'styles'
import { Column, H2, Row, Text, Button } from 'styles/global'

import Img from 'assets/landings/company/img3.svg'
import { Img3, PartColumn } from '../style'
import { Link } from 'react-router-dom'
import { routes } from 'router'

const Part3 = ({ handleClickButton }: { handleClickButton: (e: any, path: string) => void }) => {
  return (
    <PartColumn id="title-2">
      <H2 margin="0 0 30px 0" width="100%" position="center" weight="600" size="24px">
        Marché du recrutement en pharmacie
      </H2>
      <Row wrap justifyContent="space-between">
        <Column responsive="width: 100%;" width="60%">
          <Text margin="15px 0">
            Comprendre le marché du <strong>recrutement en pharmacie</strong> est une étape
            incontournable pour tout recruteur qui souhaite attirer et retenir les{' '}
            <strong>professionnels du domaine pharmaceutique</strong>. En France, le secteur
            pharmaceutique représente plus de 120 000 emplois, répartis entre la pharmacie
            d'officine, l'hôpital, l'industrie et la recherche. Selon les dernières données de
            l'Ordre national des pharmaciens, le nombre de pharmaciens en activité est en croissance
            constante.
            <br />
            <br /> Cependant, le <strong>marché du recrutement en pharmacie</strong> présente des
            spécificités qui lui sont propres. En premier lieu, il s'agit d'un marché fortement
            régulé, notamment en termes de formations et de qualifications requises. Pour exercer,
            un pharmacien doit être titulaire d'un diplôme d'État de docteur en pharmacie et être
            inscrit à l'Ordre national des pharmaciens.
            <br />
            <br /> Ensuite, le recrutement de pharmaciens est souvent confronté à un déséquilibre de
            l'offre et de la demande, en particulier dans certaines spécialités ou certaines zones
            géographiques.
            <br />
            <br />
            Enfin, le recrutement en pharmacie requiert une connaissance fine des différents métiers
            et carrières du secteur, de leurs enjeux et de leurs évolutions.
            <br />
            <br /> C'est pour répondre à ces spécificités et accompagner les recruteurs dans leur
            démarche que notre plateforme, emploi-pharmacien, propose une gamme de{' '}
            <strong>services dédiée au recrutement de pharmaciens</strong>.
          </Text>
          <Button.Primary width="250px" onClick={(e) => handleClickButton(e, routes.contact.path)}>
            Nous contacter
          </Button.Primary>
        </Column>
        <Img3 responsive="display: none" src={Img} alt="recrutement pharmaciens" />
      </Row>
    </PartColumn>
  )
}

export default Part3
