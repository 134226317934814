import { Salary } from 'types'

export const parseSalary = ({ salary }: { salary: Salary | string }): string => {
  if (typeof salary === 'string') {
    // ancienne version
    return salary
  }

  if(salary.isNotSpecified || !salary.from){
    return "Non spécifié"
  }

  const frequency =
    salary.frequency === 'PER_HOUR'
      ? 'par heure'
      : salary.frequency === 'PER_MONTH'
      ? 'par mois'
      : salary.frequency === 'PER_YEAR'
      ? 'par an'
      : salary.frequency
  const maxSalary = salary.to ? `à ${salary.to}€` : ''
  return `${salary.from}€ ${maxSalary} ${frequency}`
}
