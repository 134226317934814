import React from 'react'
import { H2, Text } from 'styles/global'

import { PartColumn } from '../style'

const Part7 = () => {
  return (
    <PartColumn id="title-6">
      <H2 margin="0 0 30px 0" width="100%" position="center" weight="600" size="24px">
        Les services d'emploi-pharmacien pour le recrutement de pharmaciens
      </H2>
      <Text margin="15px 0">
        Emploi-pharmacien se positionne comme un partenaire de choix dans votre{' '}
        <strong>processus de recrutement de pharmaciens</strong>. Notre objectif est de vous
        proposer des services adaptés pour vous aider à trouver rapidement et efficacement le
        pharmacien qui correspond à vos besoins.
        <br />
        <br /> Pour cela, nous mettons à votre disposition un espace dédié pour la publication de
        vos offres d'emploi. Grâce à notre audience qualifiée, vos annonces bénéficient d'une
        visibilité optimale auprès des professionnels du secteur pharmaceutique.
        <br />
        <br /> Nous vous proposons également l'accès à notre répertoire de CV de pharmaciens. Ce
        service vous offre l'opportunité d'économiser du temps en identifiant directement les
        candidats les plus aptes à répondre à vos exigeances.
        <br />
        <br /> Enfin, nous proposons un accompagnement personnalisé pour optimiser votre processus
        de recrutement. Nos conseillers, experts en recrutement dans le secteur pharmaceutique, sont
        à votre écoute pour vous aider à définir votre stratégie de recrutement et à rédiger vos
        annonces.
        <br />
        <br /> En choisissant emploi-pharmacien pour le{' '}
        <strong>recrutement de vos pharmaciens</strong>, vous bénéficiez de notre expertise et de
        notre réseau pour vous aider à franchir avec succès toutes les étapes du processus.
      </Text>
    </PartColumn>
  )
}

export default Part7
