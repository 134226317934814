import React, { Dispatch, SetStateAction } from 'react'
import { Column, H3, Row, Text, Button } from 'styles/global'

import Img from 'assets/landings/company/img7.svg'
import { Img2, PartColumn } from '../style'
import { routes } from 'router'

const Part9 = ({ handleClickButton }: { handleClickButton: (e: any, path: string) => void }) => {
  return (
    <PartColumn blue id="title-8">
      <Row wrap width="100%" justifyContent="space-between">
        <Column responsive="width: 100%;" width="60%">
          <H3 margin="0 0 30px 0" width="100%" position="start" weight="500" size="24px">
            Comment rendre mon annonce plus attractive pour attirer les meilleurs candidats
            pharmaciens ?
          </H3>
          <Text margin="15px 0">
            Pour rendre votre annonce attractive, il est important de soigner le titre et la
            description du poste. Présentez de manière claire et précise le poste à pourvoir, le
            profil recherché, et les avantages offerts par votre entreprise. N'hésitez pas à mettre
            en avant les valeurs de votre entreprise et à personnaliser le message en fonction du
            profil de pharmacien recherché.
          </Text>
        </Column>
        <Column responsive="width: 100%;" width="30%">
          <Img2 style={{ width: '100%', marginBottom: '20px' }} src={Img} alt="cvThèque" />
          
        </Column>
      </Row>
    </PartColumn>
  )
}

export default Part9
