import { Modal } from 'antd'
import React, { useState } from 'react'
import { Column, Text, Title } from 'styles/global'
import { InfoIcon } from '../../style'

import infoImg from 'assets/icons/info.png'

const BackTop = () => {
  const [isDisplayed, setIsDisplayed] = useState<boolean>(false)
  return (
    <>
      <InfoIcon onClick={() => setIsDisplayed(true)} src={infoImg} alt="info" />

      <Modal
        footer={[]}
        visible={isDisplayed}
        onCancel={(e) => {
          e.preventDefault()
          e.stopPropagation()
          setIsDisplayed(false)
        }}
      >
        <Column width="100%" alignItems="center">
          <Text margin="0 0 20px 0">
            Les offres d'emploi avec l'option de remise en top activée, sont automatiquement remises
            en haut de la liste des offres d'emploi sur emploi-pharmacien.fr après deux semaines.
            <br />
            Soit en moyenne à la moitié de leur cycle d'apparition, dans le but de leur redonner un
            boost de visibilité.
          </Text>
        </Column>
      </Modal>
    </>
  )
}

export default BackTop
