import { styled, mediaQuery, theme } from 'styles'
import { Row, Column, Text } from 'styles/global'
import keyIcon from 'assets/icons/key.svg'

export const KeyIcon = styled.img.attrs({
  src: keyIcon,
})`
  width: 40px;
  height: auto;
  margin-bottom: 10px;
`

export const ColumnResetPasswordRules = styled(Column)`
  background: ${theme.color.beige};
  min-height: 165px;
  padding: 15px;
`

export const Rules = styled(Text)`
  color: ${({ valid }: { valid: boolean }) => valid && theme.color.primary};
  text-decoration: ${({ valid }: { valid: boolean }) => valid && 'line-through'};
`

export const LinkedinButton = styled(Row)`
  background: #2169B4;
  color: white;
  font-size: 16px;
  font-weight: 600;
  border-radius: 4px,
  border: 1px solid ${theme.color.greyBlack};
  cursor: pointer;
  padding: 12px 20px;
  padding-left: 80px;
  align-items: center;
  width: 100%;
  height: 45px;
  position: relative;
  ${mediaQuery.mobile}{
    font-size: 14px
  }
`

export const LinkedinButtonImg = styled.img`
  height: 40px;
  position: absolute;
  left: 0px;
  border-radius-top-left: 4px;
  border-radius-bottom-left: 4px;
`


export const Role = styled.img`
  width: 45%;
  border-radius: 6px;
  border: 1px solid ${theme.color.grey};
  cursor: pointer;
  ${({ actif }: { actif: boolean }) => actif && `border: 3px solid ${theme.color.primary}`};
`
