import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Divider, message } from 'antd'
import { convertFromRaw } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'

import { InformationsContent } from './style'
import { Card, Row, Text, H4, H3, Button, H2, H1, Column } from 'styles/global'
import { JobOffer } from 'types/index'
import ViewContact from 'components/GlobalComponents/viewContact'
import { ApplyToJobOffer } from 'components'
import { authUtils, parseSalary } from 'utils'
import { ACCOUNT_TYPES } from 'constants/index'
import { adminServices } from 'services'

const JobOfferCard = ({ jobOffer }: { jobOffer: JobOffer }): ReactElement => {
  const [htmlDescription, setHtmlDescription] = useState<string>()
  const [isContactModalOpen, setIsContactModalOpen] = useState<boolean>(false)

  useEffect(() => {
    try {
      const content = convertFromRaw(JSON.parse(jobOffer.description))
      //@ts-ignore
      setHtmlDescription(stateToHTML(content))
    } catch(err) {

    }
  }, [])

  const formattedDescription = jobOffer?.description
    ?.toString()
    ?.split('\n')
    ?.map((item, key) => {
      return (
        <React.Fragment key={key}>
          {item}
          <br />
        </React.Fragment>
      )
    })

  const handleAdminPushOnJoinDotCom = () => {
    adminServices.postJobOfferOnJoin({ jobOfferId: jobOffer._id || '' }).then((res) => {
      if (res.status === 200) {
        message.success('job offer push on Join.com')
      } else {
        message.success('job offer already push on join.com')
      }
    })
  }

  return (
    <Card
      id={`job-offer-${jobOffer._id}`}
      responsive="width: 95vw"
      padding="15px 25px"
      width="70vw"
      margin="30px 0 0 0"
      key={jobOffer._id}
    >
      {authUtils.currentRole() === ACCOUNT_TYPES.ADMIN && (
        <Button.Primary
          margin="0 0 30px 0"
          disable={jobOffer.muticanalPublished}
          onClick={handleAdminPushOnJoinDotCom}
        >
          Envoyer sur join.com
        </Button.Primary>
      )}
      <ViewContact
        email={jobOffer.contact.email}
        phone={jobOffer.contact.phone}
        companyName={jobOffer.officeName}
        isDisplayed={isContactModalOpen}
        setIsDisplayed={setIsContactModalOpen}
      />
      <Row wrap justifyContent="space-between" width="100%">
        <Column>
          <ApplyToJobOffer
            buttonElement={<Button.Primary>Postuler à l'offre d'emploi</Button.Primary>}
            jobOffer={jobOffer}
          />
        </Column>

        <Column
          alignItems="flex-end"
          width="65%"
          maxWidth="calc(100% - 300px)"
          responsive="width: 100%; max-width: 100%; align-items: flex-start; margin-top: 15px;"
        >
          <H2
            responsive="margin-bottom: 15px; width: 100%; margin-right: 0;"
            ellipsis
            size="14px"
            weight="500"
            color="blue"
            underline
          >
            {jobOffer.userId ? (
              <Link to={`/profil-entreprise?companyId=${jobOffer.userId}`} target="_blank">
                {jobOffer.officeName}
              </Link>
            ) : (
              <Text color="blue">{jobOffer.officeName}</Text>
            )}
          </H2>
        </Column>
      </Row>
      <Column width="100%">
        <H1 width="90%" ellipsis margin="25px 0 0 0" size="20px">
          {jobOffer.title}
        </H1>
        {jobOffer.viewCount && jobOffer.viewCount > 180 && (
          <Text margin="5px 0 0 0" size="13px" color="greyBlack">
            {jobOffer.viewCount} vues
          </Text>
        )}
      </Column>
      <InformationsContent>
        {jobOffer.contractType && (
          <Row wrap width="100%">
            <Text
              responsive="width: 100%; margin-bottom: 5px"
              width="45%"
              position="start"
              color="secondary"
            >
              Type de contrat :
            </Text>
            <Text responsive="width: 100%;">{jobOffer.contractType}</Text>
          </Row>
        )}
        {jobOffer.jobType && (
          <>
            <Divider style={{ margin: '10px 0 10px 0' }} />
            <Row wrap width="100%">
              <Text
                responsive="width: 100%; margin-bottom: 5px"
                width="45%"
                position="start"
                color="secondary"
              >
                Type de poste :
              </Text>
              <Text responsive="width: 100%;">{jobOffer.jobType}</Text>
            </Row>
          </>
        )}
        {jobOffer.startDate && (
          <>
            <Divider style={{ margin: '10px 0 10px 0' }} />
            <Row wrap width="100%">
              <Text
                responsive="width: 100%; margin-bottom: 5px"
                width="45%"
                position="start"
                color="secondary"
              >
                Début de missions :
              </Text>
              <H4 responsive="width: 100%;" ellipsis width="50%">
                {jobOffer.startDate}
              </H4>
            </Row>
          </>
        )}
        {jobOffer.workingHours && (
          <>
            <Divider style={{ margin: '10px 0 10px 0' }} />
            <Row wrap width="100%">
              <Text
                responsive="width: 100%; margin-bottom: 5px"
                width="45%"
                position="start"
                color="secondary"
              >
                Temps de travail :
              </Text>
              <H4 responsive="width: 100%;" ellipsis width="50%">
                {jobOffer.workingHours}
              </H4>
            </Row>
          </>
        )}
        {jobOffer.salary && (
          <>
            <Divider style={{ margin: '10px 0 10px 0' }} />
            <Row wrap width="100%">
              <Text
                responsive="width: 100%; margin-bottom: 5px"
                width="45%"
                position="start"
                color="secondary"
              >
                Salaire proposé (€):
              </Text>
              <H3 responsive="width: 100%;" ellipsis width="50%">
                {parseSalary({ salary: jobOffer.salary })}
              </H3>
            </Row>
          </>
        )}
        {jobOffer.address && (
          <>
            <Divider style={{ margin: '10px 0 10px 0' }} />
            <Row wrap width="100%">
              <Text
                responsive="width: 100%; margin-bottom: 5px"
                width="45%"
                position="start"
                color="secondary"
              >
                Adresse :
              </Text>
              <H2 responsive="width: 100%;" ellipsis width="50%">
                {`${jobOffer.address.street && jobOffer.address.street + ', '}` +
                  `${jobOffer.address.city && jobOffer.address.city + ', '}` +
                  `${jobOffer.address.postalCode && jobOffer.address.postalCode + ', '}` +
                  `${jobOffer.address.region && jobOffer.address.region + ', '}` +
                  `${jobOffer.address.country && jobOffer.address.country}`}
              </H2>
            </Row>
          </>
        )}
      </InformationsContent>
      {htmlDescription ? (
        <Text margin="20px 0 0 0" dangerouslySetInnerHTML={{ __html: htmlDescription }} />
      ) : (
        formattedDescription && <Text margin="20px 0 0 0">{formattedDescription}</Text>
      )}
    </Card>
  )
}

export default JobOfferCard
