import { Modal } from 'antd'
import React, { useState } from 'react'
import { Column, Text, Title } from 'styles/global'
import { InfoIcon } from '../../style'

import infoImg from 'assets/icons/info.png'

import img from '../assets/job_boards.png'

const Multicanal = () => {
  const [isDisplayed, setIsDisplayed] = useState<boolean>(false)
  return (
    <>
      <InfoIcon onClick={() => setIsDisplayed(true)} src={infoImg} alt="info" />

      <Modal footer={[]} visible={isDisplayed} onCancel={(e) => {
        e.preventDefault()
        e.stopPropagation()
        setIsDisplayed(false)
      }}>
        <Column width="100%" alignItems="center">
          <Text margin="0 0 20px 0">
            Grâce nos partenaires et outils de diffusion multicanale, chaque offre d'emploi publiée
            sur emploi-pharamcien.fr est automatiquement rediffusée sur +10 autres jobs boards
            différents. Dans le but d'apporter encore plus de visibilité et de candidats potentiels
            sur votre offre d'emploi.
            <br />
            <br />
            Bien que cela représente un cout, nous avons décidé de l'inclure dans nos tarifs et de
            vous l'offrir, sans frais supplémentaires
          </Text>
          <Title margin="30px 0 0 0">Liste des jobs boards avec diffusion multicanale</Title>
          <img src={img} alt="illustration" style={{ width: '100%', marginTop: '30px' }} />
        </Column>
      </Modal>
    </>
  )
}

export default Multicanal
