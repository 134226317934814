import React, { Dispatch, SetStateAction } from 'react'
import { Modal } from 'antd'

import salesIllustration from './assets/illustration1.svg'
import salesIllustration2 from './assets/illustration2.svg'
import salesIllustration3 from './assets/illustration3.svg'
import salesIllustration4 from './assets/illustration4.svg'
import salesIllustration5 from './assets/illustration5.svg'
import salesIllustration6 from './assets/illustration6.svg'
import { PromotionIllustration } from './style'

interface Props {
  setIsDisplayed: Dispatch<SetStateAction<boolean>>
}

const PromotionInformationsModal = ({ setIsDisplayed }: Props) => {
  return (
    <Modal className="big-modal-2" visible={true} onCancel={() => setIsDisplayed(false)} footer={[]}>
      <PromotionIllustration alt="promotion illustration" src={salesIllustration} />
      <PromotionIllustration alt="promotion illustration" src={salesIllustration2} />
      <PromotionIllustration alt="promotion illustration" src={salesIllustration3} />
      <PromotionIllustration alt="promotion illustration" src={salesIllustration4} />
      <PromotionIllustration alt="promotion illustration" src={salesIllustration5} />
      <PromotionIllustration alt="promotion illustration" src={salesIllustration6} />
    </Modal>
  )
}

export default PromotionInformationsModal
