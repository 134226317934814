import React, { ReactElement, useState, Dispatch, SetStateAction } from 'react'

import LoginForm from './LoginForm'
import ResetPasswordModal from './ResetPassword'
import { Modal } from 'antd'

type Props = {
  isDisplayed: string
  setIsDisplayed: Dispatch<SetStateAction<string>>
  isRegistration?: boolean
  defaultEmail?: string
  defaultTypeAccount?: string
}

function Login({ isDisplayed, setIsDisplayed, isRegistration, defaultEmail, defaultTypeAccount }: Props): ReactElement {
  const [isResetPasswordDisplayed, setIsResetPasswordDisplayed] = useState<boolean>(false)

  const onCancel = () => {
    setIsDisplayed('')
  }

  return (
    <Modal
      style={{ width: '400px', maxWidth: '90vw' }}
      visible={isDisplayed ? true : false}
      onCancel={onCancel}
      footer={[]}
    >
      <ResetPasswordModal
        isDisplayed={isResetPasswordDisplayed}
        setIsDisplayed={setIsResetPasswordDisplayed}
      />
      <LoginForm
        defaultEmail={defaultEmail}
        setIsResetPasswordDisplayed={setIsResetPasswordDisplayed}
        redirection={isDisplayed}
        setIsDisplayed={setIsDisplayed}
        isRegistration={isRegistration}
        defaultTypeAccount={defaultTypeAccount}
      />
    </Modal>
  )
}

export default Login
