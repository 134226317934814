import React from 'react'

import { Checkbox, Form, Input, InputNumber, Radio } from 'antd'
import { Column, Text } from 'styles/global'
import { Salary } from 'types'

interface Props {
  setSalary: (value: Salary) => void
  salary: Salary
  title?: string
  isNotSpecifiedDisplayed?: boolean
}

const SalaryInput = ({ setSalary, salary, title, isNotSpecifiedDisplayed }: Props) => {
  return (
    <Form.Item
      name="salary"
      label={
        <Column>
          <Text>
            <b>{title || "Salaire proposé"}</b>
          </Text>
        </Column>
      }
      rules={[
        {
          message: 'Veuillez renseigner le salaire',
        },
      ]}
      style={{ marginTop: '30px' }}
    >
      <Radio.Group
        value={salary?.frequency || ""}
        onChange={(e) => setSalary({ ...salary, frequency: e.target.value })}
      >
        <Radio style={{ margin: '4px 8px' }} value={'PER_HOUR'}>
          Taux horaire
        </Radio>
        <Radio style={{ margin: '4px 8px' }} value={'PER_MONTH'}>
          Salaire mensuel
        </Radio>
        <Radio style={{ margin: '4px 8px' }} value={'PER_YEAR'}>
          Salaire annuel
        </Radio>
      </Radio.Group>

      <Input.Group
        key={salary?.frequency || ""}
        style={{ alignItems: 'center', display: 'flex', marginTop: '15px' }}
        compact
      >
        <InputNumber
          disabled={salary?.isNotSpecified || false}
          onChange={(value) => setSalary({ ...salary, from: value || 0 })}
          style={{ width: '120px' }}
          placeholder="0"
          //@ts-ignore
          autosize={false}
          defaultValue={salary?.from || ''}
          min={11.52}
          max={300000}
          addonAfter={'€'}
        />
        <span style={{ margin: '0 10px' }}>à</span>
        <InputNumber
          disabled={salary?.isNotSpecified || false}
          onChange={(value) => setSalary({ ...salary, to: value || 0 })}
          style={{ width: '120px' }}
          placeholder="0"
          //@ts-ignore
          autosize={false}
          defaultValue={salary?.to || ''}
          min={11.52}
          max={300000}
          addonAfter={'€'}
        />

        <span style={{ margin: '0 0 0 10px' }}>
          brut par{' '}
          {salary?.frequency === 'PER_HOUR'
            ? 'heure'
            : salary?.frequency === 'PER_MONTH'
            ? 'mois'
            : 'an'}
        </span>
      </Input.Group>
      {isNotSpecifiedDisplayed &&
      <Checkbox
        checked={salary?.isNotSpecified || false}
        style={{ marginTop: '7px' }}
        onChange={(e) => setSalary({ ...salary, isNotSpecified: e.target.checked })}
      >
        En fonction du profil
      </Checkbox>}
    </Form.Item>
  )
}

export default SalaryInput
