import React, { Dispatch, SetStateAction } from 'react'
import { Column, H3, H2, Row, Text, Button } from 'styles/global'

import Img from 'assets/landings/candidat/img8.svg'
import { Img2, PartColumn } from '../style'

const Part10 = ({ handleClickButton }: { handleClickButton: (e: any, path: string) => void }) => {
  return (
    <PartColumn blue id="title-7">
      <Row width="100%" wrap justifyContent="space-between">
        <Column responsive="display: none" width="30%">
          <Img2 style={{ width: '100%', marginBottom: '20px' }} src={Img} alt="secteur officine" />
        </Column>
        <Column responsive="width: 100%;" width="60%">
          <H3 margin="0 0 30px 0" width="100%" position="start" weight="500" size="24px">
            Quels sont les types de contrats disponibles ?
          </H3>
          <Text margin="15px 0">
            Sur Emploi-Pharmacien, vous trouverez une multitude de contrats disponibles, en fonction
            de vos besoins et de vos aspirations professionnelles : CDI pour une stabilité et une
            sécurité de l'emploi, CDD pour une expérience temporaire ou un besoin de flexibilité, ou
            encore des missions d'intérim pour des remplacements.
          </Text>
        </Column>
      </Row>
    </PartColumn>
  )
}

export default Part10
