import { AdminServices } from 'types/api'
import { PATHS, ROUTE, getHeader, response } from 'api'

const admin: AdminServices = {
  findCompanies: async () => {
    const headers = await getHeader('default', true)
    const promise = await fetch(`${ROUTE(PATHS.ADMIN.COMPANIES)}`, {
      method: 'GET',
      headers,
    })
    return await response({ promise, serviceType: 'jobOffer', service: 'find' })
  },
  findCandidates: async () => {
    const headers = await getHeader('default', true)
    const promise = await fetch(`${ROUTE(PATHS.ADMIN.CANDIDATES)}`, {
      method: 'GET',
      headers,
    })
    return await response({ promise, serviceType: 'jobOffer', service: 'find' })
  },
  updateUser: async (body, { id }) => {
    const headers = await getHeader('default', true)
    const promise = await fetch(`${ROUTE(PATHS.ADMIN.UPDATE_USER)}/${id}`, {
      method: 'PUT',
      body: JSON.stringify(body),
      headers,
    })
    return await response({ promise, serviceType: 'jobOffer', service: 'find' })
  },
  loginToAccount: async ({ id }) => {
    const headers = await getHeader('default', true)
    const promise = await fetch(`${ROUTE(PATHS.ADMIN.LOGIN_TO_ACCOUNT)}/${id}`, {
      method: 'GET',
      headers,
    })
    return await response({ promise, serviceType: 'jobOffer', service: 'find' })
  },
  postJobOfferOnJoin: async ({ jobOfferId }) => {
    const headers = await getHeader('default', true)
    const promise = await fetch(`${ROUTE(PATHS.ADMIN.JOIN_DOT_COM)}/${jobOfferId}`, {
      method: 'POST',
      headers,
    })
    return await response({ promise, serviceType: 'jobOffer', service: 'find' })
  },
}

export default admin
