import React from 'react'
import { Button, Column, H3, Row, Text } from 'styles/global'

import Img from 'assets/landings/candidat/img5.svg'
import { Img3, PartColumn } from '../style'
import { routes } from 'router'
import { Link } from 'react-router-dom'

const Part5 = ({ handleClickButton }: { handleClickButton: (e: any, path: string) => void }) => {
  return (
    <PartColumn id="title-4">
      <Row wrap width="100%" justifyContent="space-between">
        <Column responsive="width: 100%" width="60%">
          <H3 margin="0 0 30px 0" width="100%" position="start" weight="600" size="24px">
            Comment utiliser efficacement la plateforme Emploi-Pharmacien
          </H3>
          <Text margin="15px 0">
            Pour optimiser votre recherche d’
            <strong>offres d'emploi de pharmacien d’officine</strong> ou hospitalier, il est
            important de comprendre nos fonctionnalités clés et comment les utiliser efficacement.
          </Text>
          <ol>
            <li style={{ marginBottom: '10px' }}>
              <Link style={{ textDecoration: 'underline' }} to={routes.viewOffers.path}>
                Recherche avancée
              </Link>{' '}
              : Vous pouvez filtrer les offres par type de contrat (CDI, CDD, intérim...), par
              localisation ou encore par spécialité. Cette précision vous permet d'accéder
              rapidement aux offres qui correspondent le plus à vos critères.
            </li>
            <li>
              <span
                onClick={(e) => handleClickButton(e, 'default')}
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
              >
                Dépôt de CV
              </span>{' '}
              : Le dépôt de CV en ligne est une opportunité à ne pas négliger. Cela permet aux
              recruteurs de vous contacter directement pour des postes correspondant à votre profil.
              <br />
              <span
                onClick={(e) => handleClickButton(e, 'default')}
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
              >
                Alertes emploi
              </span>{' '}
              : En vous inscrivant aux alertes emploi, vous recevez directement dans votre boîte
              mail les nouvelles offres correspondant à vos critères. Vous ne manquerez ainsi aucune
              opportunité.
            </li>
          </ol>
          <Text margin="15px 0">
            Pour maximiser vos chances, il est recommandé de soigner votre profil en ligne, en
            remplissant toutes les informations demandées et en téléchargeant un CV récent et bien
            rédigé. La régularité dans la consultation des offres et la réactivité dans l'envoi des
            candidatures est également un facteur clés pour optimiser votre recherche d'emploi de
            pharmacien.
          </Text>
          <Button.Primary style={{width: "250px"}} onClick={(e) => handleClickButton(e, 'default')}>
            Partager mon CV
          </Button.Primary>
        </Column>
        <Img3 src={Img} alt="déposer CV" />
      </Row>
    </PartColumn>
  )
}

export default Part5
