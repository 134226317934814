import React, { Dispatch, SetStateAction } from 'react'
import { Column, H2, Row, Text, Button } from 'styles/global'

import Img from 'assets/landings/company/img4.svg'
import { Img2, PartColumn } from '../style'

const Part4 = ({ handleClickButton }: { handleClickButton: (e: any, path: string) => void }) => {
  return (
    <PartColumn blue id="title-3">
      <H2 margin="0 0 30px 0" width="100%" position="center" weight="600" size="24px">
        Annonce et recrutement de pharmaciens
      </H2>
      <Row width="100%" wrap justifyContent="space-between">
        <Column responsive="width: 100%; margin-bottom: 20px;" width="35%">
          <Img2
            style={{ width: '100%', marginBottom: '20px' }}
            responsive="width: calc(100% - 10vw) !important;"
            src={Img}
            alt="publier une offre"
          />
          <Button.Third width="100%" onClick={(e) => handleClickButton(e, 'default')}>
            Publier une offre d’emploi
          </Button.Third>
        </Column>
        <Column responsive="width: 100%;" width="60%">
          <Text margin="15px 0">
            Le <strong>recrutement de pharmaciens</strong> nécessite une stratégie réfléchie et
            adaptée au marché.
            <br />
            <br /> Plusieurs approches sont possibles :
            <ul style={{ margin: '10px 0' }}>
              <li style={{ marginBottom: '10px' }}>
                La publication d'annonces d'emploi, que ce soit sur des sites généralistes ou
                spécialisés comme emploi-pharmacien. Cette méthode présente l'avantage d'attirer un
                grand nombre de candidats.
              </li>
              <li style={{ marginBottom: '10px' }}>
                L'utilisation des réseaux sociaux professionnels comme LinkedIn est une autre
                approche intéressante. Elle permet de cibler directement les pharmaciens en activité
                et de bénéficier du bouche-à-oreille numérique. Cependant, cette méthode demande
                énormément de temps ainsi que des compétences spécifiques en matière de
                communication digitale.
              </li>
              <li style={{ marginBottom: '10px' }}>
                Le recours à un cabinet de recrutement spécialisé dans le secteur pharmaceutique est
                également une option. Cette solution offre un gain de temps et une garantie de
                qualité, mais représente un investissement financier généralement très important.
              </li>
            </ul>
            Chaque stratégie a donc des avantages. Le choix dépendra des ressources et des objectifs
            spécifiques de chaque recruteur. Néanmoins, une plateforme comme emploi-pharmacien,
            spécialisée dans le recrutement de pharmaciens, peut constituer une solution combinant
            les atouts de ces différentes approches.
          </Text>
        </Column>
      </Row>
    </PartColumn>
  )
}

export default Part4
