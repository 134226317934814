import { Modal } from 'antd'
import React, { useState } from 'react'
import { Column, Text, Title } from 'styles/global'
import { InfoIcon } from '../../style'

import infoImg from 'assets/icons/info.png'

import img from '../assets/banner.png'

const Banner = () => {
  const [isDisplayed, setIsDisplayed] = useState<boolean>(false)
  return (
    <>
      <InfoIcon onClick={() => setIsDisplayed(true)} src={infoImg} alt="info" />

      <Modal footer={[]} visible={isDisplayed} onCancel={(e) => {
        e.preventDefault()
        e.stopPropagation()
        setIsDisplayed(false)
      }}>
        <Column width="100%" alignItems="center">
          <Text margin="0 0 20px 0">
            Les offres d'emploi avec un bandeau sont davantage visibles depuis notre liste d'offres d'emploi à pourvoir
          </Text>
          <Title size="15px" margin="20px 0 0 0">Exemple d'offre d'emploi avec un bandeau :</Title>
          <img src={img} alt="illustration" style={{ width: '100%', marginTop: '30px' }} />
        </Column>
      </Modal>
    </>
  )
}

export default Banner
