import React, { useContext, useState } from 'react'
import { Helmet } from 'react-helmet'

import { Title, Row, Column, Button, Text, H1, H2, H3, H4, Card } from 'styles/global'
import { Skeletons } from 'components'
import { urlParams, parseSalary } from 'utils'
import { Col, Divider, message, Modal } from 'antd'
import { userService } from 'services'
import { useQuery } from 'react-query'
import { Candidate } from 'types'
import { ProfilePicture, InformationsContent } from './style'
import { UserContext } from 'providers'

import profileImg from 'assets/icons/profile.svg'

const CandidateRender = () => {
  const [isViewContacts, setIsViewContacts] = useState<boolean>(false)
  const { user } = useContext(UserContext)
  const [candidate, setCandidate] = useState<Candidate>()
  const [isContentDisplayed, setIsContentDisplayed] = useState<boolean>(false)
  const paramsUrl = urlParams()

  const isConnectedCandidate = user._id === paramsUrl.id

  const { isLoading } = useQuery(
    [paramsUrl.id, user._id, 'candidateFindOne'],
    async () => userService.candidate({ id: paramsUrl.id }),
    {
      onSuccess: (res) => {
        setCandidate(res)
        const informations = res
        if (
          informations &&
          (informations.contractTypes?.length ||
            informations.desiredSalary ||
            informations.experienceYears ||
            informations.localisations?.countrys?.length ||
            informations.localisations?.regions?.length)
        ) {
          setIsContentDisplayed(true)
        }
      },
    },
  )

  return (
    <Column width="100%" alignItems="center">
      <Helmet>
        <title>
          Profil candidat {candidate?.jobType || ''}{' '}
          {candidate?.localisations?.regions?.[0] || ''} : {candidate?.name || ''}
        </title>
        <meta
          name="description"
          content={`Profil ${candidate?.jobType || ''} de ${
            candidate?.name || ''
          }, zones géographiques: 
          ${
            candidate?.localisations?.regions &&
            ', ' + candidate?.localisations?.regions?.join(',  ')
          }.`}
        />
        <meta
          name="keywords"
          content={`profil, candidat, ${candidate?.jobType || ''}`}
        />
      </Helmet>
      {isViewContacts && (
        <Modal visible={isViewContacts} onCancel={() => setIsViewContacts(false)} footer={[]}>
          <Column width="100%" padding="50px">
            <Text size="16px" margin="0 0 30px 0">
              Coordonnées de <span style={{ fontWeight: 'bold' }}>{candidate?.name}</span>
            </Text>
            {candidate?.email && (
              <Text>
                <span style={{ fontWeight: 'bold' }}>Adresse email : </span> {candidate.email}
              </Text>
            )}
            {candidate?.phone && (
              <Text>
                <span style={{ fontWeight: 'bold' }}>Téléphone : </span> {candidate.phone}
              </Text>
            )}
          </Column>
        </Modal>
      )}
      <Card padding="100px 25px 50px 25px" width="100%">
        {isLoading && !candidate ? (
          <Skeletons />
        ) : (
          candidate && (
            <Row responsive="flex-direction: column" justifyContent="center" width="100%">
              <ProfilePicture alt="profil picture" src={candidate?.profilePicture || profileImg} />
              <Column responsive="max-width: 100%" maxWidth="80%">
                <Row
                  responsive="min-width: auto;"
                  minWidth="700px"
                  justifyContent="space-around"
                  wrap
                  alignItems="flex-end"
                >
                  <Column>
                    <Text color="greyBlack">{candidate?.jobType || ''}</Text>
                    <Title
                      responsive="max-width: 280px; margin: 0 20px 20px 0"
                      maxWidth="350px"
                      ellipsis
                      size="18px"
                      weight="bold"
                    >
                      {candidate.name}
                    </Title>
                  </Column>
                  {(candidate.phone || candidate.email) && (
                    <Button.Third
                      onClick={() => setIsViewContacts(true)}
                      responsive="margin: 10px;"
                    >
                      Obtenir ses coordonnées
                    </Button.Third>
                  )}
                  {candidate.cv && (
                    <a target="_blank" rel="noreferrer noreferrer" href={candidate.cv} download>
                      <Button.Third responsive="margin: 10px;">Consulter son CV</Button.Third>
                    </a>
                  )}
                </Row>
                {isContentDisplayed && (
                  <InformationsContent>
                    {candidate.jobType && (
                      <>
                        <Row wrap width="100%">
                          <Text
                            responsive="width: 100%; margin-bottom: 5px"
                            width="45%"
                            position="start"
                            color="secondary"
                          >
                            Type de poste recherché :
                          </Text>
                          <Text responsive="width: 100%;">{candidate.jobType}</Text>
                        </Row>

                        <Divider style={{ margin: '10px 0 10px 0' }} />
                      </>
                    )}
                    {candidate.desiredSalary && (
                      <Row wrap width="100%">
                        <Text
                          responsive="width: 100%; margin-bottom: 5px"
                          width="45%"
                          position="start"
                          color="secondary"
                        >
                          Salaire souhaité :
                        </Text>
                        <Text responsive="width: 100%;">
                          {parseSalary({ salary: candidate.desiredSalary })}
                        </Text>
                      </Row>
                    )}
                    {(candidate.localisations?.regions?.length > 0 ||
                      candidate.localisations?.countrys?.length > 0) && (
                      <>
                        <Divider style={{ margin: '10px 0 10px 0' }} />
                        <Row wrap width="100%">
                          <H2
                            responsive="width: 100%; margin-bottom: 5px"
                            width="45%"
                            position="start"
                            color="secondary"
                          >
                            Zones géographiques :
                          </H2>
                          <H2 responsive="width: 100%;" ellipsis width="50%">
                            {candidate.localisations.countrys.join(', ')}
                            {candidate.localisations.regions &&
                              ', ' + candidate.localisations.regions.join(',  ')}
                          </H2>
                        </Row>
                      </>
                    )}
                    {candidate.contractTypes?.length !== 0 && (
                      <>
                        <Divider style={{ margin: '10px 0 10px 0' }} />
                        <Row wrap width="100%">
                          <H3
                            responsive="width: 100%; margin-bottom: 5px"
                            width="45%"
                            position="start"
                            color="secondary"
                          >
                            Contrat recherché :
                          </H3>
                          <H3 responsive="width: 100%;" ellipsis width="50%">
                            {candidate.contractTypes.join(',  ')}
                          </H3>
                        </Row>
                      </>
                    )}
                    {candidate.experienceYears && (
                      <>
                        <Divider style={{ margin: '10px 0 10px 0' }} />
                        <Row wrap width="100%">
                          <H4
                            responsive="width: 100%; margin-bottom: 5px"
                            width="45%"
                            position="start"
                            color="secondary"
                          >
                            Années d’expérience :
                          </H4>
                          <Text responsive="width: 100%;" ellipsis width="50%">
                            {candidate.experienceYears}
                          </Text>
                        </Row>
                      </>
                    )}
                    {candidate.school && (
                      <>
                        <Divider style={{ margin: '10px 0 10px 0' }} />
                        <Row wrap width="100%">
                          <Text
                            responsive="width: 100%; margin-bottom: 5px"
                            width="45%"
                            position="start"
                            color="secondary"
                          >
                            École :
                          </Text>
                          <H2 responsive="width: 100%;" ellipsis width="50%">
                            {candidate.school}
                          </H2>
                        </Row>
                      </>
                    )}
                  </InformationsContent>
                )}
              </Column>
            </Row>
          )
        )}
      </Card>
    </Column>
  )
}

export default CandidateRender
