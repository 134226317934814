export const anchorItems = [
  {
    key: '1',
    href: '#title-1',
    title: 'Recrutement dans le secteur officine',
  },
  {
    key: '2',
    href: '#title-3',
    title: 'Annonce et recrutement de pharmaciens',
  },
  {
    key: '3',
    href: '#title-4',
    title: 'Optimiser le processus de recrutement',
  },
  {
    key: '4',
    href: '#title-5',
    title: "l'Accompagnement dans le recrutement",
  },
  {
    key: '5',
    href: '#title-7',
    title: 'Questions fréquentes',
  },
]