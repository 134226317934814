import React, { Dispatch, SetStateAction, useContext, useState } from 'react'
import { Modal, Select, message } from 'antd'

import { Title, Row, Text, Button, Column } from 'styles/global'
import { theme } from 'styles'
import { Checkout } from 'components'
import { userService } from 'services'
import { UserContext } from 'providers'

import validImg from 'assets/illustrations/valid.svg'
import { routes } from 'router'

const { Option } = Select

interface Props {
  setIsDisplayed: Dispatch<SetStateAction<boolean>>
}

const pricing = {
  '10': 390,
  '25': 890,
  '100': 2900,
}

const BuyCredits = ({ setIsDisplayed }: Props) => {
  const { setUser } = useContext(UserContext)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [creditVolume, setCreditVolume] = useState<number | 'contact'>(25)

  const handlePaymentSuccessful = () => {
    userService.me().then((res) => {
      setUser(res)
      setIsSuccess(true)
      message.success('Commande passée avec succès, crédits mis à jour')
    })
  }

  const handleContact = () => {
    window.open(
      routes.contact.path + '?message=Je souhaiterais échanger à propos des tarifs sur mesure',
      '_blank' // ceci ouvre un nouvel onglet
    )
  }

  return (
    <Modal visible={true} footer={[]} onCancel={() => setIsDisplayed(false)}>
      {isSuccess ? (
        <Column alignItems="center" width="100%">
          <Title margin="0 0 30px 0">Achat réalisé avec succès</Title>
          <img src={validImg} alt="payment valid" style={{ width: '200px', margin: '40px 0' }} />
          <Text>
            Une facture va vous être envoyée par email
            <br />
            Vous pouvez maintenant publier vos offres d'emploi en utilisant vos crédits
          </Text>
          <Button.Third onClick={() => setIsDisplayed(false)} margin="30px 0 0 0">
            Compris
          </Button.Third>
        </Column>
      ) : (
        <Column alignItems="center" width="100%">
          <Title margin="0 0 30px 0">Acheter des crédits</Title>
          <Text size="13px" color="greyBlack" margin="0 0 5px 0" width="100%" position="start">
            Sélectionner le nombre de crédits
          </Text>
          <Select
            style={{ width: '100%', marginBottom: '30px' }}
            value={creditVolume}
            placeholder="Ville"
            onChange={(value) => setCreditVolume(value)}
          >
            <Option value={10}>10 crédits (390€)</Option>
            <Option value={25}>25 crédits (890€)</Option>
            <Option value={100}>100 crédits (2900€)</Option>
            <Option value={'contact'}>Offre sur mesure </Option>
          </Select>
          {creditVolume === 'contact' ? (
            <Button.Primary onClick={handleContact}>Prendre contact</Button.Primary>
          ) : (
            <Column alignItems="center" width="100%">
              <Row width="100%" justifyContent="space-between">
                <Text>Nombre de crédits sélectionnés :</Text>
                <Text>{creditVolume} crédits</Text>
              </Row>
              <Row margin="0 0 30px 0" width="100%" justifyContent="space-between">
                <Text>
                  Total TTC{' '}
                  <span style={{ fontSize: '12px', color: theme.color.greyBlack }}>
                    (non assujetti à la TVA) :
                  </span>
                </Text>
                <Text>{pricing[creditVolume]}€</Text>
              </Row>
              <Checkout
                price={pricing[creditVolume]}
                credits={creditVolume}
                successCallback={handlePaymentSuccessful}
              />
            </Column>
          )}
        </Column>
      )}
    </Modal>
  )
}

export default BuyCredits
