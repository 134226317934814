export const anchorItems = [
  {
    key: '1',
    href: '#title-1',
    title: "Une plateforme dédiée à l'emploi en officine",
  },
  {
    key: '2',
    href: '#title-3',
    title: "Utilité d'une plateforme spécialisée ?",
  },
  {
    key: '3',
    href: '#title-4',
    title: "Comment utiliser efficacement la plateforme Emploi-Pharmacien ?",
  },
  {
    key: '4',
    href: '#title-5',
    title: "Types d'emplois pharmaciens disponibles",
  },
  {
    key: '5',
    href: '#title-6',
    title: "Comment préparer sa candidature",
  },
  {
    key: '6',
    href: '#title-7',
    title: 'Questions fréquentes',
  },
]