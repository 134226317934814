import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { message, Checkbox, Radio, Modal } from 'antd'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import { Text, Input, Title, Row, Column, Button } from 'styles/global'
import { ClicGif, Form, Illustration } from './style'
import { userService } from 'services'
import { DepartmentsSelect, UploadImage, Loader } from 'components'
import { UserContext } from 'providers/UserProvider'
import CitySelect from 'components/GlobalComponents/citySelect'
import { ACCOUNT_TYPES } from 'constants/index'
import { authUtils } from 'utils'

import clicGif from 'assets/illustrations/clic.gif'
import validImg from 'assets/illustrations/valid.svg'

interface MinimumCandidateInformations {
  name: string
  localisations: {
    departments: string[]
    cities: string[]
  }
  cv: string
  jobType: string
  isPublished: boolean
}

const CandidateRequiredInformationsModal = () => {
  const [candidateInformations, setCandidateInformations] = useState<MinimumCandidateInformations>({
    name: '',
    localisations: {
      departments: [],
      cities: [],
    },
    cv: '',
    jobType: '',
    isPublished: true,
  })
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [cv, setCv] = useState<any>([])
  const { user, setUser } = useContext(UserContext)
  const [isChange, setIsChange] = useState<boolean>(false)
  const [isPublishedProfile, setIsPublishedProfile] = useState<boolean>(true)

  const isDisplayed =
    authUtils.currentRole() === ACCOUNT_TYPES.CANDIDATE &&
    (!user.candidateInformations?.name || !user.candidateInformations?.cv)

  const onObjectChange = (attr1: string, attr2: string, value: string) => {
    if (!isChange) {
      setIsChange(true)
    }
    setCandidateInformations({
      ...candidateInformations,
      [attr1]: { ...candidateInformations[attr1], [attr2]: value },
    })
  }

  const handleChange = (attr, value) => {
    if (!isChange) {
      setIsChange(true)
    }
    setCandidateInformations({ ...candidateInformations, [attr]: value })
  }

  const handleSave = () => {
    if (setIsLoading) {
      setIsLoading(true)
    }
    userService
      .update({
        isPublished: isPublishedProfile,
        candidateInformations: {
          ...candidateInformations,
          cv: cv[0]?.base64 || candidateInformations.cv || '',
        },
      })
      .then((res) => {
        if (setIsLoading) {
          setIsLoading(false)
        }
        if (res.status === 200) {
          setIsChange(false)
          message.success('Informations sauvegardées avec succés !')
          userService.me().then((res) => {
            setUser(res)
            window.location.reload()
          })
        }
      })
  }

  if (!isDisplayed) {
    return <></>
  }

  return (
    <Modal
      className="big-modal"
      closable={false}
      style={{ width: '75vw !important' }}
      footer={[]}
      visible={true}
    >
      {isLoading && <Loader isDisplayed={isLoading} />}
      <Title margin="0 0 50px 0" color="secondary">
        Complétez votre compte
      </Title>
      <Form
        scrollToFirstError
        responsive="max-width: 850px;"
        width="100%"
        margin="50px 0 0 0"
        layout="vertical"
        onFinish={handleSave}
        isStyled
        id="candidat-informations-form"
      >
        <Illustration src={validImg} alt="illustration" />
        <Row style={{ position: 'relative' }} margin="0 0 50px 0">
          <Column>
            <Text margin="10px 0 10px 0" weight="bold">
              Votre CV (pdf)
            </Text>
            <Form.Item
              rules={[
                {
                  required: !cv?.length,
                  validator: !cv?.length,
                  message: 'Veuillez importer votre CV',
                },
              ]}
              style={{ width: '100%' }}
              name="CV"
              id="CV"
            >
              <UploadImage type=".pdf" fileList={cv} setFileList={setCv} maxImages={1} />
            </Form.Item>
          </Column>
          <ClicGif src={clicGif} alt="clic here" />
        </Row>
        <Row
          responsive="justify-content: flex-start"
          justifyContent="center"
          position="start"
          width="100%"
          wrap
        >
          <Column style={{ minWidth: '280px', width: '280px' }} margin="3%">
            <Form.Item
              style={{ width: '200px' }}
              name="name"
              id="name"
              onChange={(e) => handleChange('name', e.target.value)}
              label={<Text weight="bold">Nom et prénom</Text>}
              rules={[{ required: true, message: 'Veuillez renseigner votre nom et prénom' }]}
            >
              <Input maxLength={35} width="100%" />
            </Form.Item>
            <Form.Item
              style={{ width: '200px' }}
              name="jobType"
              onChange={(e) => handleChange('jobType', e.target.value)}
              label={<Text weight="bold">Type de poste recherché</Text>}
              rules={[
                { required: true, message: 'Veuillez renseigner votre type de poste recherché' },
              ]}
            >
              <Radio.Group value={candidateInformations.jobType}>
                <Radio style={{ width: '130px' }} value={'pharmacien'}>
                  Pharmacien(ne)
                </Radio>
                <Radio style={{ width: '130px' }} value={'assistant pharmacien'}>
                  Assistant(e) Pharmacien
                </Radio>
                <Radio style={{ width: '130px' }} value={'préparateur'}>
                  Préparateur
                </Radio>
                <Radio style={{ width: '130px' }} value={'étudiant'}>
                  Étudiant(e)
                </Radio>
                <Radio style={{ width: '130px' }} value={'rayonniste'}>
                  Rayonniste
                </Radio>
                <Radio style={{ width: '130px' }} value={'apprenti'}>
                  Apprenti(e)
                </Radio>
                <Radio style={{ width: '130px' }} value={'administratif'}>
                  Administratif
                </Radio>
                <Radio style={{ width: '130px' }} value={'diététicien'}>
                  Diététicien(ne)
                </Radio>
                <Radio style={{ width: '130px' }} value={'autre'}>
                  Autre
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Column>

          <Column style={{ minWidth: '250px' }} margin="3%" width="25%">
            <Text margin="0 0 10px 0" size="14px" weight="bold">
              Zones géographique <br />
              <span style={{ fontWeight: '400' }}>(où êtes vous prêt à intervenir)</span>
            </Text>

            <Form.Item
              rules={[
                {
                  required: !candidateInformations.localisations?.departments?.length,
                  message: 'Veuillez renseigner votre département',
                },
              ]}
              style={{ width: '100%' }}
              name="Départements"
              label="Départements"
              id="Départements"
            >
              <DepartmentsSelect
                defaultValue={candidateInformations.localisations?.departments}
                isMultiple={true}
                setSelectedDepartments={(value) =>
                  onObjectChange('localisations', 'departments', value)
                }
              />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: !candidateInformations.localisations?.cities?.length,
                  message: 'Veuillez renseigner votre ville',
                },
              ]}
              style={{ width: '100%' }}
              name="Cities"
              label="Villes"
              id="Villes"
            >
              <CitySelect
                defaultValue={candidateInformations.localisations?.cities}
                isMultiple={true}
                setSelectedCity={(value) => onObjectChange('localisations', 'cities', value)}
              />
            </Form.Item>
          </Column>
        </Row>

        <Checkbox
          defaultChecked={!isPublishedProfile}
          onChange={(isChecked) => setIsPublishedProfile(!isPublishedProfile)}
        >
          <Text margin="0 0 0 10px">Je ne souhaite pas rendre mon profil public</Text>
        </Checkbox>
        <Button.Primary
          margin="30px 0 0 0"
          type="primary"
          htmlType="submit"
          weight="bold"
          fontSize="18px"
        >
          Enregistrer mes information
        </Button.Primary>
      </Form>
    </Modal>
  )
}

export default CandidateRequiredInformationsModal
