import { JobOffer } from 'types'
import { stateToHTML } from 'draft-js-export-html'
import { convertFromRaw } from 'draft-js'

export const generateJobOfferJsonld = (jobOffer: JobOffer) => {
  const {
    title,
    description,
    contractType,
    createdDate,
    workingHours,
    address,
    contact,
    salary,
    officeName,
    _id,
  } = jobOffer

  let parsedDescription = description
  try {
    const content = convertFromRaw(JSON.parse(jobOffer.description))
    //@ts-ignore
    parsedDescription = stateToHTML(content)
  } catch {}

  const startDate = jobOffer.startDate
  let validThrough = ''

  // Vérification si la date est valide
  if (Date.parse(startDate)) {
    const date = new Date(startDate)
    date.setMonth(date.getMonth() + 3) // Ajoute 3 mois à la date
    validThrough = date.toISOString() // Convertit la date en format ISO
  }

  const jobPosting = {
    '@context': 'https://schema.org',
    '@type': 'JobPosting',
    title: title,
    description: parsedDescription,
    identifier: {
      '@type': 'PropertyValue',
      name: 'Emploi-Pharmacien',
      value: `https://emploi-pharmacien.fr/offre-emploi-pharmacien?id=${_id}`,
    },
    datePosted: createdDate ? new Date(createdDate).toISOString() : new Date().toISOString(),
    employmentType: contractType,
    workHours: workingHours,
    hiringOrganization: {
      '@type': 'Organization',
      name: officeName,
    },
    jobLocation: {
      '@type': 'Place',
      address: {
        '@type': 'PostalAddress',
        streetAddress: address.street,
        addressLocality: address.city,
        addressRegion: address.region?.toUpperCase(),
        postalCode: address.postalCode + '000',
        addressCountry: 'FR',
      },
    },
    baseSalary: {
      '@type': 'MonetaryAmount',
      currency: 'EUR',
      minValue: salary.from,
      maxValue: salary.to || undefined,
    },
    applicationContact: {
      '@type': 'ContactPoint',
      email: contact.email,
      telephone: contact.phone,
    },
    occupationalCategory: '29-1051',
    skills:
      "Connaissance des médicaments, capacité à donner des conseils sur les médicaments, compétences en communication, compétences en gestion, capacité à travailler sous pression, attention aux détails",
    salaryCurrency: 'EUR',
    validThrough: validThrough,
    url: `https://emploi-pharmacien.fr/offre-emploi-pharmacien?id=${_id}`
  }

  return JSON.stringify(jobPosting, null, 2)
}

/*

Pour les ambulanciers :
occupationalCategory : Le code SOC pour les ambulanciers est généralement "53-3011".
skills : "Conduite d'ambulance, premiers secours, gestion des situations d'urgence, compétences en communication, empathie"

Pour les dentistes :
occupationalCategory : Le code SOC pour les dentistes est généralement "29-1021".
skills : "Compétences en dentisterie générale, compétences en chirurgie buccale, compétences en orthodontie, compétences en endodontie, compétences en parodontie, compétences en communication, compétences en gestion de la douleur"

Pour les pharmaciens :
occupationalCategory : Le code SOC pour les pharmaciens est généralement "29-1051".
skills : "Connaissance des médicaments, capacité à donner des conseils sur les médicaments, compétences en communication, compétences en gestion, capacité à travailler sous pression, attention aux détails"

*/
