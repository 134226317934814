import { AuthServices } from 'types/api'
import { PATHS, ROUTE, getHeader, response } from 'api'

const login: AuthServices = {
  login: async (body) => {
    const headers = await getHeader('default', false)
    const promise = await fetch(`${ROUTE(PATHS.AUTH.DEFAULT)}`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers,
    })
    return await response({ promise, serviceType: 'auth', service: 'login' })
  },

  registration: async (body) => {
    const headers = await getHeader('default', false)
    const promise = await fetch(`${ROUTE(PATHS.AUTH.REGISTRATION)}`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers,
    })
    return await response({ promise, serviceType: 'auth', service: 'registration' })
  },

  linkedinAuth: async (body) => {
    const headers = await getHeader('default', false)
    const promise = await fetch(`${ROUTE(PATHS.AUTH.LINKEDIN_AUTH)}`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers,
    })
    return await response({ promise, serviceType: 'auth', service: 'registration' })
  },

  switch: async (body) => {
    const headers = await getHeader('default', true)
    const promise = await fetch(`${ROUTE(PATHS.AUTH.DEFAULT)}`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers,
    })
    return await response({ promise, serviceType: 'auth', service: 'switch' })
  },

  reset: async (body) => {
    const headers = await getHeader('default', false)
    const promise = await fetch(`${ROUTE(PATHS.AUTH.RESET)}`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers,
    })
    return await response({ promise, serviceType: 'auth', service: 'reset' })
  },

  validReset: async (body) => {
    const headers = await getHeader('default', false)
    const promise = await fetch(`${ROUTE(PATHS.AUTH.VALID_RESET)}`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers,
    })
    return await response({ promise, serviceType: 'auth', service: 'validReset' })
  },
}

export default login
