import React, { Dispatch, SetStateAction } from 'react'
import { Column, H2, Row, Text, Button } from 'styles/global'

import Img from 'assets/landings/company/img2.svg'
import { Img2, PartColumn } from '../style'
import { routes } from 'router'
import { Link } from 'react-router-dom'

const Part2 = ({ handleClickButton }: { handleClickButton: (e: any, path: string) => void }) => {
  return (
    <PartColumn id="title-1">
      <H2 margin="0 0 30px 0" width="100%" position="center" weight="600" size="24px">
        Recrutement dans le secteur officine
      </H2>
      <Row wrap justifyContent="space-between">
        <Img2 src={Img} alt="recrutement pharmacien" />
        <Column responsive="width: 100%;" width="60%">
          <Text margin="15px 0">
            Le rôle des pharmaciens dans le secteur de la santé ne cesse de gagner en importance.
            Bien plus que de simples dispensateurs de médicaments, les pharmaciens sont aujourd'hui
            reconnus comme des professionnels de santé à part entière, des acteurs clés dans le
            parcours de soins des patients. Ils participent activement à l'amélioration de la
            qualité des soins, à la prévention et à l'éducation thérapeutique. Leur expertise et
            leur savoir-faire sont de plus en plus sollicités, ce qui a pour conséquence d'accroître
            les besoins de <strong>recrutement dans le secteur pharmaceutique</strong>.
            <br />
            <br />
            Cependant, le <strong>recrutement de pharmaciens</strong> peut représenter un véritable
            défi pour de nombreuses structures, que ce soient des pharmacies de ville, des hôpitaux,
            des centres de recherche ou encore des entreprises de l'industrie pharmaceutique. La
            complexité de ces recrutements réside principalement dans la nécessité de trouver des
            professionnels hautement qualifiés. C’est là qu’
            <Link to={routes.home.path}>emploi-pharmacien </Link>
            intervient.
          </Text>
          <Button.Primary onClick={(e) => handleClickButton(e, 'default')}>
            Publier une offre d’emploi
          </Button.Primary>
        </Column>
      </Row>
    </PartColumn>
  )
}

export default Part2
