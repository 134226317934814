import React, { Dispatch, SetStateAction } from 'react'
import { H2, Row, Text, Button } from 'styles/global'

import { PartColumn } from '../style'

const Part6 = ({ handleClickButton }: { handleClickButton: (e: any, path: string) => void }) => {
  return (
    <PartColumn blue id="title-5">
      <H2 margin="0 0 30px 0" width="100%" position="center" weight="600" size="24px">
        Types d'emplois pharmaciens disponibles
      </H2>
      <Text margin="15px 0">
        Durant votre recherche d'emploi de pharmacien, vous pouvez être confronté à un large
        éventail de possibilités. Sur emploi-pharmacien, nous mettons à votre disposition une
        multitude d'offres, que ce soit en Contrat à Durée Indéterminée (CDI) ou en Contrat à Durée
        Déterminée (CDD).
        <br />
        <br /> Les emplois en CDI représentent une opportunité intéressante pour les pharmaciens
        souhaitant s'inscrire dans la durée au sein d'une officine, d'un hôpital ou d'une entreprise
        pharmaceutique, alors que les emplois en CDD peuvent être une excellente option pour
        acquérir de l'expérience, découvrir différents environnements de travail ou pour répondre à
        un besoin de flexibilité. <br />
        <br />
        Outre ces contrats, emploi-pharmacien propose aussi des offres d'emplois au sein de
        nombreuses entreprises du secteur pharmaceutique. Des grands laboratoires internationaux aux
        PME spécialisées, les opportunités sont variées. Ces postes peuvent représenter une belle
        alternative à la pratique en officine ou à l'hôpital, et offrent souvent des perspectives de
        carrière enrichissantes.
        <br />
        <br /> Ainsi, quelle que soit votre aspiration professionnelle, vous trouverez les
        opportunités qui vous correspondent.
      </Text>
    </PartColumn>
  )
}

export default Part6
