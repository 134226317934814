import React, { Dispatch, SetStateAction } from 'react'
import { Column, H3, H2, Row, Text, Button } from 'styles/global'

import Img from 'assets/landings/company/img8.svg'
import { Img2, PartColumn } from '../style'
import { routes } from 'router'

const Part8 = ({ handleClickButton }: { handleClickButton: (e: any, path: string) => void }) => {
  return (
    <PartColumn blue id="title-7">
      <Row width="100%" wrap justifyContent="space-between">
        <Column responsive="width: 100%; margin-bottom: 30px;" width="30%">
          <Img2
          responsive="display: none"
            style={{ width: '100%', marginBottom: '20px' }}
            src={Img}
            alt="outils recrutement"
          />
          <Button.Third
            width="100%"
            onClick={(e) => handleClickButton(e, routes.candidateList.path)}
          >
            Découvrir notre cvThèque
          </Button.Third>
        </Column>
        <Column responsive="width: 100%;" width="60%">
          <H2 margin="0 0 30px 0" width="100%" position="start" weight="500" size="24px">
            Comment gérer efficacement le processus d'entretien lors du recrutement de pharmaciens ?
          </H2>
          <Text margin="15px 0">
            Pour gérer efficacement le processus d'entretien, il est essentiel de bien préparer vos
            questions et de proposer des mises en situation pour évaluer concrètement les
            compétences des candidats. N'oubliez pas que l'entretien est également l'occasion pour
            le candidat d'évaluer l'entreprise. Soyez donc transparent, accueillant et ouvert aux
            questions.
          </Text>
        </Column>
      </Row>
    </PartColumn>
  )
}

export default Part8
