import React, { Dispatch, SetStateAction } from 'react'
import { Column, H3, H2, Row, Text, Button } from 'styles/global'

import Img from 'assets/landings/candidat/img6.svg'
import { Img2, PartColumn } from '../style'

const Part8 = ({ handleClickButton }: { handleClickButton: (e: any, path: string) => void }) => {
  return (
    <PartColumn blue id="title-7">
      <H2 margin="0 0 90px 0" width="100%" position="center" weight="600" size="30px">
        Questions fréquentes
      </H2>
      <Row width="100%" wrap justifyContent="space-between">
        <Column responsive="width: 100%; margin-bottom: 30px;" width="30%">
          <Img2 style={{ width: '100%', marginBottom: '20px' }} src={Img} alt="secteur officine" />
        </Column>
        <Column responsive="width: 100%;" width="60%">
          <H3 margin="0 0 30px 0" width="100%" position="start" weight="500" size="24px">
            Comment trouver rapidement un emploi pharmacien ?
          </H3>
          <Text margin="15px 0">
            Pour accélérer votre recherche d'emploi de pharmacien, utilisez notre moteur de
            recherche avancé pour filtrer les offres en fonction de vos critères (localisation, type
            de contrat, spécialité, etc.). N'hésitez pas à déposer votre CV sur notre plateforme
            pour être directement contacté par les recruteurs. Enfin, inscrivez-vous aux alertes
            emploi pour recevoir les nouvelles offres directement dans votre boîte mail.
          </Text>
        </Column>
      </Row>
    </PartColumn>
  )
}

export default Part8
