import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { Container } from './style'
import { Title, Row, Column, Button, Text, H1, H2, H3, H4 } from 'styles/global'
import { routes } from 'router'
import { CreateEditJobOfferForm, Loader, NeedConnexion } from 'components'
import { authUtils, urlParams } from 'utils'
import { message } from 'antd'
import { jobOfferService } from 'services'
import { ACCOUNT_TYPES } from 'constants/index'
import { useQuery } from 'react-query'
import { UserContext } from 'providers/UserProvider'

const CreateJobOffer = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { user } = useContext(UserContext)

  const navigate = useNavigate()
  const { checkoutSessionId, userId, editedOfferId } = urlParams()

  const { data: defaultJobOffer } = useQuery(
    [`edit-offer-${editedOfferId}`],
    async () => jobOfferService.findOne({ id: editedOfferId }),
    {
      enabled: !!editedOfferId,
      staleTime: 2000,
    },
  )

  // CHECK SUBSCRIPTION
  useEffect(() => {
    if (checkoutSessionId) {
      setIsLoading(true)
      if (checkoutSessionId === 'failed') {
        message.error('An error occurred with the payment, please try again or contact us')
        setIsLoading(false)
      }
    }
  }, [])

  return (
    <Container>
      <Helmet>
        <title>Emploi-pharmacien | créer une offre d'emploi</title>
        <meta name="description" content="Créer et publier une offre d'emploi pharmacien" />
        <meta name="keywords" content="offre, emploi, pharmacien" />
      </Helmet>
      <Loader isDisplayed={isLoading} />
      <CreateEditJobOfferForm defaultJobOffer={defaultJobOffer?.data} setIsLoading={setIsLoading} />
      {authUtils.currentRole() !== ACCOUNT_TYPES.COMPANY && <NeedConnexion />}
    </Container>
  )
}

export default CreateJobOffer
