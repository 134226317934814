import React, { Dispatch, SetStateAction } from 'react'
import { Column, H2, Row, Text, Button } from 'styles/global'

import Img from 'assets/landings/candidat/img4.svg'
import { Img2, PartColumn } from '../style'

const Part4 = ({ handleClickButton }: { handleClickButton: (e: any, path: string) => void }) => {
  return (
    <PartColumn blue id="title-3">
      <Row width="100%" wrap justifyContent="space-between">
        <Column responsive="width: 100%; margin-bottom: 20px;" width="35%">
          <Img2
            style={{ width: '100%', marginBottom: '20px' }}
            responsive="width: calc(100% - 10vw) !important;"
            src={Img}
            alt="interface saas"
          />
        </Column>
        <Column responsive="width: 100%;" width="60%">
          <H2 margin="0 0 30px 0" width="100%" position="start" weight="600" size="24px">
            L'utilité d'une plateforme spécialisée dans la recherche d'emploi pharmacien
          </H2>
          <Text margin="15px 0">
            La recherche d'<strong>emploi de préparateur en pharmacie</strong> ou de pharmacien
            d’officine peut s'avérer être un véritable parcours du combattant. Et c'est là
            qu’emploi-pharmacien intervient. Dédiée exclusivement aux{' '}
            <strong>emplois du domaine de la pharmacie</strong>, notre plateforme vous permet de
            trouver en quelques clics des offres qui correspondent précisément à vos critères de
            recherche.
            <br />
            <br /> L’avantage de travailler avec nous ? La pertinence des résultats obtenus :
            contrairement à des sites d'emploi généralistes, chaque offre que vous trouvez sur
            emploi-pharmacien correspond à un <strong>job en pharmacie</strong> bien précis. Vous
            pouvez donc gagner un temps précieux dans votre recherche et accéder rapidement à des
            offres de qualité.
            <br />
            <br /> Notre plateforme vous permet également de déposer votre CV en ligne et de vous
            rendre visible auprès de nombreux recruteurs, en plus de mettre à votre disposition des
            ressources utiles, comme des conseils pour la rédaction de votre CV et lettre de
            motivation, ou encore des informations détaillées sur les entreprises qui recrutent.
            <br />
            <br /> En somme, emploi-pharmacien est une ressource indispensable pour{' '}
            <strong>optimiser votre recherche d'emploi de pharmacien</strong> et vous aider à
            trouver le poste qui correspond à vos attentes et à vos ambitions professionnelles.
          </Text>
          <Button.Third onClick={(e) => handleClickButton(e, 'default')}>
            Accéder aux outils emploi-pharmacien.fr
          </Button.Third>
        </Column>
      </Row>
    </PartColumn>
  )
}

export default Part4
