import { InputButton } from 'components'
import React, { Dispatch, SetStateAction } from 'react'
import { Button, Column, H2, H3, Row, Text } from 'styles/global'

import Img from 'assets/landings/candidat/img1.svg'
import img2 from '../assets/candidate/img1.svg'
import img3 from '../assets/candidate/img2.svg'
import img4 from '../assets/candidate/img3.svg'
import { PartRow, Img1, Img2 } from '../style'
import { routes } from 'router'
import { Link } from 'react-router-dom'
import { theme } from 'styles'
import { PartColumn } from 'pages/companyHome/style'

const CandidatePart = ({
  handleClickButton,
  setDefaultLoginEmail,
}: {
  handleClickButton: (e: any, path: string) => void
  setDefaultLoginEmail: Dispatch<SetStateAction<string>>
}) => {
  return (
    <PartColumn width="100%">
      <Row width="100%" wrap justifyContent="space-between">
        <Img1 src={Img} alt="Recherche emploi pharmacien" />
        <Column responsive="width: 95%" width="50%">
          <H2 weight="500" size="22px">
            Recherche emploi pharmacien : des opportunités prometteuses
          </H2>
          <Text margin="15px 0 15px 0" weight="300">
            Inscrivez vous gratuitement afin d’accéder aux offres d’emploi et partager votre profil
            aux recruteurs
          </Text>
          <InputButton
            callback={(value, e) => {
              setDefaultLoginEmail(value)
              handleClickButton(e, 'default')
            }}
            buttonContent="Rejoindre Emploi Pharmacien"
            placeholder="Adresse email"
          />
        </Column>
      </Row>

      <Text margin="30px 0 0 0">
        Se projeter dans l'avenir est une démarche essentielle pour tout professionnel diplômé.
        Cependant, pour le pharmacien, la recherche d'emploi peut s'apparenter à un parcours semé
        d'interrogations. Emploi-pharmacien est une plateforme visant à vous assister dans votre
        quête professionnelle. En proposant une gamme d'opportunités soigneusement sélectionnées,
        nous nous positionnons comme un véritable assistant pour tout pharmacien en{' '}
        <Link
          style={{ color: theme.color.blue, textDecoration: 'underline' }}
          to={routes.candidateHome.path}
        >
          recherche d'emploi{' '}
        </Link>
        .
      </Text>
      <H3 margin="50px 0 30px 0" width="100%" position="start" weight="600" size="24px">
        Un éventail d'offres d'emploi de pharmacien
      </H3>
      <Text>
        Nous veillons constamment à l'actualité et à la pertinence des offres en mettant à jour
        notre base de données en continu. Chaque <strong>offre d'emploi de pharmacien</strong> que
        vous découvrez est donc récente et d'actualité. Ce suivi en temps réel est une garantie pour
        vous de ne manquer aucune opportunité intéressante. Vous avez également la possibilité de
        déposer votre CV en ligne, de le rendre visible aux recruteurs potentiels et de vous
        positionner en candidat actif.
      </Text>
      <Column margin="70px 0" id="title-3">
        <Row width="100%" wrap justifyContent="space-between">
          <Column responsive="width: 100%; margin-bottom: 20px;" width="35%">
            <Img2
              style={{ width: '100%', marginBottom: '20px' }}
              responsive="width: calc(100% - 10vw) !important;"
              src={img2}
              alt="interface saas"
            />
          </Column>
          <Column responsive="width: 100%;" width="60%">
            <H3 margin="0 0 30px 0" width="100%" position="start" weight="600" size="24px">
              Une plateforme pour une recherche d'emploi de pharmacien
            </H3>
            <Text margin="15px 0">
              Emploi-pharmacien ne se contente pas de lister des offres d'emploi. La plateforme vous
              offre également la possibilité de parcourir les pages dédiées aux{' '}
              <strong>entreprises du secteur pharmaceutique actuellement en recrutement</strong>.
              Ainsi, vous disposez de toutes les informations nécessaires pour vous informer de
              manière détaillée et ciblée avant de soumettre votre candidature. Cette approche vous
              permet de comprendre précisément le cadre dans lequel vous envisagez de travailler et
              de vous y adapter au mieux.
            </Text>
            <Link to={routes.viewOffers.path}>
              <Button.Third>Voir les offres d'emploi</Button.Third>
            </Link>
          </Column>
        </Row>
      </Column>

      <Column margin="20px 0 30px 0" responsive="width: 100%;" width="100%">
        <H3 margin="0 0 30px 0" width="100%" position="start" weight="600" size="24px">
          Une plateforme pour une recherche d'emploi de pharmacien
        </H3>
        <Text margin="15px 0">
          Emploi-pharmacien ne se contente pas de lister des offres d'emploi. La plateforme vous
          offre également la possibilité de parcourir les pages dédiées aux{' '}
          <strong>entreprises du secteur pharmaceutique actuellement en recrutement</strong>. Ainsi,
          vous disposez de toutes les informations nécessaires pour vous informer de manière
          détaillée et ciblée avant de soumettre votre candidature. Cette approche vous permet de
          comprendre précisément le cadre dans lequel vous envisagez de travailler et de vous y
          adapter au mieux.
        </Text>
      </Column>

      <Column margin="40px 0 40px 0" id="title-3">
        <Row width="100%" wrap justifyContent="space-between">
          <Column responsive="width: 100%;" width="60%">
            <H3 margin="0 0 30px 0" width="100%" position="start" weight="600" size="24px">
              Une recherche d'emploi de pharmacien régulièrement mise à jour
            </H3>
            <Text margin="15px 0">
              Nous veillons constamment à l'actualité et à la pertinence des offres en mettant à
              jour notre base de données en continu. Chaque{' '}
              <strong>offre d'emploi de pharmacien</strong> que vous découvrez est donc récente et
              d'actualité. Ce suivi en temps réel est une garantie pour vous de ne manquer aucune
              opportunité intéressante. Vous avez également la possibilité de déposer votre CV en
              ligne, de le rendre visible aux recruteurs potentiels et de vous positionner en
              candidat actif.
            </Text>
            <Button.Primary onClick={(e) => handleClickButton(e, 'default')}>
              Rejoindre Emploi-Pharmacien
            </Button.Primary>
          </Column>
          <Column responsive="width: 100%; margin-bottom: 20px;" width="35%">
            <Img2
              style={{ width: '100%', marginBottom: '20px' }}
              responsive="width: calc(100% - 10vw) !important;"
              src={img3}
              alt="interface saas"
            />
          </Column>
        </Row>
      </Column>
      <H3 margin="20px 0 30px 0" width="100%" position="start" weight="600" size="24px">
        Emploi-pharmacien, un allié incontournable dans votre recherche d'emploi de pharmacien
      </H3>
      <Text margin="0 0 30px 0">
        Emploi-pharmacien est l’outil central de votre{' '}
        <strong>recherche d'emploi de pharmacien</strong>. Notre plateforme conjugue qualité des
        offres, facilité d'utilisation et réactivité. Ainsi, nous vous accompagnons efficacement
        dans cette étape importante de votre carrière. Rejoignez-nous et découvrez le monde
        d'opportunités qui vous attend dans l'univers de la pharmacie.
      </Text>
    </PartColumn>
  )
}

export default CandidatePart
