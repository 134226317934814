import { styled, theme, mediaQuery } from 'styles'
import { Column, Row } from 'styles/global'

export const PartRow = styled(Row)`
  background: ${({ blue }: { blue: boolean }) => (blue ? theme.color.blueLight : '')};
  padding: 50px 15%;
  width: 100%;
  ${mediaQuery.mobile} {
    padding: 30px 20px;
  }
`

export const PartColumn = styled(Column)`
  background: ${({ blue }: { blue: boolean }) => (blue ? theme.color.blueLight : '')};
  padding: 50px 15%;
  width: 100%;
  ${mediaQuery.mobile} {
    padding: 30px 20px;
  }
`

export const Img1 = styled.img`
  width: 40%;
  max-width: 600px;
  border-radius: 8px;
  ${mediaQuery.mobile} {
    width: calc(100% - 10vw);
    margin: 5vw;
    ${({ responsive }: {responsive: string}) => responsive};
  }
`

export const Img2 = styled.img`
  width: 35%;
  max-width: 600px;
  ${mediaQuery.mobile} {
    width: calc(100% - 10vw);
    margin: 5vw;
    ${({ responsive }: {responsive: string}) => responsive};
  }
`

export const Img3 = styled.img`
  width: 30%;
  max-width: 600px;
  ${mediaQuery.mobile} {
    width: calc(100% - 10vw);
    margin: 5vw;
    ${({ responsive }: {responsive: string}) => responsive};
  }
`