import React, { ReactElement, useEffect, useState } from 'react'
import { Divider } from 'antd'
import { convertFromRaw } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'

import { Element, JobDescription, UrgentLabel } from './style'
import { Card, Row, Text, H4, H3, Button, H2, Column } from 'styles/global'
import { JobOffer } from 'types/index'
import { Link } from 'react-router-dom'
import { routes } from 'router'
import { parseSalary } from 'utils'
import { theme } from 'styles'

const JobOfferCard = ({ jobOffer, keyId }: { jobOffer: JobOffer; keyId: number }): ReactElement => {
  const [htmlDescription, setHtmlDescription] = useState()

  useEffect(() => {
    if (jobOffer.description) {
      try {
        const content = convertFromRaw(JSON.parse(jobOffer.description))
        //@ts-ignore
        setHtmlDescription(stateToHTML(content))
      } catch (err) {
      }
    }
  }, [keyId])

  const formattedDescription = jobOffer.description.split('\n').map((item, key) => {
    return (
      <React.Fragment key={key}>
        {item}
        <br />
      </React.Fragment>
    )
  })

  return (
    <>
      {jobOffer.upsell?.colorBand?.active && <UrgentLabel>Prioritaire</UrgentLabel>}
      <Card
        id={`job-offer-${keyId}`}
        responsive="width: 95vw"
        padding="15px 25px"
        width="70vw"
        margin="30px 0 0 0"
        key={keyId}
        style={{ border: jobOffer.upsell?.colorBand?.active && `4px solid ${theme.color.primary}` }}
      >
        <Row
          responsive="flex-direction: column; align-items: flex-start;"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Column responsive="width: 100%" width="65%">
            <H2 width="100%" weight="700" ellipsis size="18px" margin="0 0 5px 0">
              {jobOffer.title}
            </H2>
            <H2
              responsive="margin-bottom: 15px; width: 100%; margin-right: 0;"
              ellipsis
              size="14px"
              weight="500"
              width="100%"
              margin="0 30px 0 0"
              color="blue"
              underline
            >
              {jobOffer.userId ? (
                <Link to={`/profil-entreprise?companyId=${jobOffer.userId}`} target="_blank">
                  {jobOffer.officeName}
                </Link>
              ) : (
                <Text color="blue">{jobOffer.officeName}</Text>
              )}
            </H2>
          </Column>

          <Link to={`${routes.jobOffer.path}?id=${jobOffer._id}`}>
            <Button.Third underline color="secondary" weight="500" size="16px" height="40px">
              Voir l'offre
            </Button.Third>
          </Link>
        </Row>
        <Divider style={{ margin: '10px 0 10px 0' }} />

        <Row width="100%" wrap>
          <Element>
            Type de contrat:{' '}
            <Text margin="0 0 0 7px" weight="bold">
              {jobOffer.contractType}
            </Text>
          </Element>
          {jobOffer.jobType && (
            <Element>
              Type de poste:{' '}
              <Text margin="0 0 0 7px" weight="bold">
                {jobOffer.jobType}
              </Text>
            </Element>
          )}
          <Element>
            Salaire:{' '}
            <Text margin="0 0 0 7px" weight="bold">
              {parseSalary({ salary: jobOffer.salary })}
            </Text>
          </Element>
          <Element>
            Adresse:{' '}
            <Text margin="0 0 0 7px" weight="bold">
              {jobOffer.address.street && jobOffer.address.street + ', '}
              {jobOffer.address.postalCode}, {jobOffer.address.city}
            </Text>
          </Element>
        </Row>
        <H3 color="greyBlack" ellipsis size="16px" width="65%" margin="10px 0 7px 0">
          Description du poste
        </H3>
        <JobDescription>
          {htmlDescription ? (
            <H4 weight="400" dangerouslySetInnerHTML={{ __html: htmlDescription }} />
          ) : (
            formattedDescription && <H4 weight="400">{formattedDescription}</H4>
          )}
        </JobDescription>
      </Card>
    </>
  )
}

export default JobOfferCard
