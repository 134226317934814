import React, { Dispatch, SetStateAction, useContext, useState, useEffect } from 'react'

import { routes } from 'router'
import {
  FooterContainer,
  FooterItem,
  FooterTitle,
  FooterExternalLink,
  FooterItemText,
} from './style'
import { Column, Text, Title } from 'styles/global'

import { Link, useLocation } from 'react-router-dom'
import { authUtils } from 'utils'
import Login from 'components/login'
import { ACCOUNT_TYPES } from 'constants/index'

const Footer = () => {
  const [isLoginFormDisplayed, setIsLoginFormDisplayed] = useState<string>('')

  const isAuth = authUtils.isAuth()
  const isCompany = authUtils.currentRole() === ACCOUNT_TYPES.COMPANY
  const isCandidate = authUtils.currentRole() === ACCOUNT_TYPES.CANDIDATE

  const checkNeedConnexion = (e, redirect) => {
    if (!isAuth) {
      e.stopPropagation()
      e.preventDefault()
      setIsLoginFormDisplayed(redirect)
    }
  }

  return (
    <FooterContainer>
      {isLoginFormDisplayed && (
        <Login
          isRegistration={true}
          isDisplayed={isLoginFormDisplayed}
          setIsDisplayed={setIsLoginFormDisplayed}
        />
      )}
      <Column margin="0 45px 30px 0">
        <FooterTitle>Espace entreprise</FooterTitle>
        <FooterItem to={routes.candidateList.path}>CvThèque</FooterItem>
        <FooterItem
          onClick={(e) => checkNeedConnexion(e, routes.createOffer.path)}
          to={routes.createOffer.path}
        >
          Déposer une offre d&apos;emploi
        </FooterItem>
        {/*
        <FooterItem to={routes.contact.path}>Service de chasse</FooterItem>
      */}
      </Column>
      <Column margin="0 45px 30px 0">
        <FooterTitle>Espace pharmacien</FooterTitle>
        <FooterItem to={routes.viewOffers.path}>Consulter les offres d&apos;emploi</FooterItem>
        <FooterItem
          onClick={(e) => checkNeedConnexion(e, routes.candidateAccount.path)}
          to={routes.candidateAccount.path}
        >
          Partager mon profil
        </FooterItem>
      </Column>
      <Column margin="0 45px 30px 0">
        <FooterTitle>Offres d'emploi</FooterTitle>
        <ul>
          <li>
            <FooterItem
              to={routes.viewOffers.path + '?department=&city=Lyon'}
              style={{ fontSize: '14px' }}
            >
              Offres d'emploi pharmacien Lyon
            </FooterItem>
          </li>
          <li>
            <FooterItem
              to={routes.viewOffers.path + '?department=&city=Bordeaux'}
              style={{ fontSize: '14px' }}
            >
              Offres d'emploi pharmacien Bordeaux
            </FooterItem>
          </li>
          <li>
            <FooterItem
              to={routes.viewOffers.path + '?department=&city=Toulouse'}
              style={{ fontSize: '14px' }}
            >
              Offres d'emploi pharmacien Toulouse
            </FooterItem>
          </li>
          <li>
            <FooterItem
              to={routes.viewOffers.path + '?department=&city=Montpellier'}
              style={{ fontSize: '14px' }}
            >
              Offres d'emploi pharmacien Montpellier
            </FooterItem>
          </li>
          <li>
            <FooterItem
              to={routes.viewOffers.path + '?department=&city=Paris'}
              style={{ fontSize: '14px' }}
            >
              Offres d'emploi pharmacien Paris
            </FooterItem>
          </li>
          <li>
            <FooterItem
              to={routes.viewOffers.path + '?department=&city=Nice'}
              style={{ fontSize: '14px' }}
            >
              Offres d'emploi pharmacien Nice
            </FooterItem>
          </li>
          <li>
            <FooterItem
              to={routes.viewOffers.path + '?department=&city=Marseille'}
              style={{ fontSize: '14px' }}
            >
              Offres d'emploi pharmacien Marseille
            </FooterItem>
          </li>
          <li>
            <FooterItem
              to={routes.viewOffers.path + '?department=&city=Lille'}
              style={{ fontSize: '14px' }}
            >
              Offres d'emploi pharmacien Lille
            </FooterItem>
          </li>
        </ul>
      </Column>
      <Column margin="0 45px 30px 0">
        <FooterTitle>Liens utiles</FooterTitle>
        <FooterItem style={{ fontSize: '14px' }} to={routes.CGU.path}>
          Nous contacter
        </FooterItem>
        <a href="https://blog.emploi-pharmacien.fr/blog">
          <FooterItemText style={{ fontSize: '14px' }}>Blog</FooterItemText>
        </a>
        <FooterItem style={{ fontSize: '14px' }} to={routes.CGU.path}>
          CGU
        </FooterItem>
        <FooterItem style={{ fontSize: '14px' }} to={routes.CGV.path}>
          CGV
        </FooterItem>
      </Column>
    </FooterContainer>
  )
}

export default Footer
