import { PaymentServices } from 'types/api'
import { PATHS, ROUTE, getHeader, response } from 'api'

const payment: PaymentServices = {
  createIntent: async (body) => {
    const headers = await getHeader('default', true)
    const promise = await fetch(`${ROUTE(PATHS.PAYMENT.DEFAULT)}/intent`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers,
    })
    return await response({ promise, serviceType: '', service: '' })
  },
  createPayment: async (body) => {
    const headers = await getHeader('default', true)
    const promise = await fetch(`${ROUTE(PATHS.PAYMENT.DEFAULT)}`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers,
    })
    return await response({ promise, serviceType: '', service: '' })
  },
}

export default payment
