import React, { useState, useEffect, Dispatch, SetStateAction } from 'react'

import { CountrysSelect, DepartmentsSelect, RegionSelect } from 'components'
import { Text, Column, Select } from 'styles/global'
import { AbsolutText, Container } from './style'
import CitySelect from '../citySelect'

const { Option } = Select

export type FilterParams = {
  country: string
  region: string
  department: string
  contractType: string
  city: string
  jobType: string
}

type Props = {
  setFilterParams: Dispatch<SetStateAction<FilterParams>>
  filterParams?: FilterParams
}

const Filter = ({ setFilterParams, filterParams }: Props) => {
  const [localParams, setLocalParams] = useState<FilterParams>(
    filterParams || {
      country: '',
      region: '',
      department: '',
      contractType: '',
      city: '',
      jobType: '',
    },
  )

  useEffect(() => {
    setFilterParams(localParams)
  }, [localParams])

  return (
    <Container>
      <AbsolutText>Filtres</AbsolutText>
      <Column responsive="width: 100%" width="180px">
        <Text margin="0 0 8px 0">Secteur géographique</Text>
        <CitySelect
          style={{ width: '100%' }}
          defaultValue={localParams.city}
          setSelectedCity={(value) =>
            setLocalParams({ ...localParams, city: value === undefined ? '' : value })
          }
        />
      </Column>
      <Column responsive="width: 100%" width="180px" margin="15px 0 0 0">
        <Text margin="0 0 8px 0">Type de contrat</Text>
        <Select
          defaultValue={localParams.contractType || undefined}
          allowClear
          style={{ width: '100%' }}
          showSearch
          responsive="width: 100% !important;"
          placeholder="Type de contrat"
          onChange={(value) => setLocalParams({ ...localParams, contractType: value })}
        >
          <Option value={'CDI'}>CDI</Option>
          <Option value={'CDD & Remplacement'}>CDD & Remplacement</Option>
          <Option value={'Interim'}>Interim</Option>
          <Option value={'Contrat d’apprentissage'}>Contrat d’apprentissage</Option>
          <Option value={'Stage'}>Stage</Option>
          <Option value={'Gérance'}>Gérance</Option>
          <Option value={'Garde'}>Garde</Option>
          <Option value={'FFI'}>FFI</Option>
        </Select>
      </Column>
      <Column responsive="width: 100%" width="180px" margin="15px 0 0 0">
        <Text margin="0 0 8px 0">Type de poste</Text>
        <Select
          defaultValue={localParams.jobType || undefined}
          allowClear
          style={{ width: '100%' }}
          showSearch
          responsive="width: 100% !important;"
          placeholder="Type de poste"
          onChange={(value) => setLocalParams({ ...localParams, jobType: value })}
        >
          <Option value={'pharmacien'}>Pharmacien(ne)</Option>
          <Option value={'assistant pharmacien'}>Assistant(e) Pharmacien</Option>
          <Option value={'préparateur'}>Préparateur</Option>
          <Option value={'étudiant'}>Étudiant(e)</Option>
          <Option value={'rayonniste'}>Rayonniste</Option>
          <Option value={'apprenti'}>Apprenti(e)</Option>
          <Option value={'administratif'}>Administratif</Option>
          <Option value={'diététicien'}>Diététicien(ne)</Option>
          <Option value={'autre'}>Autre</Option>
        </Select>
      </Column>
      {/*
      <CountrysSelect
        defaultValue={localParams.country}
        style={{ margin: '15px' }}
        setSelectedCountry={(value) =>
          setLocalParams({ ...localParams, country: value === undefined ? '' : value })
        }
      />
      />*/}
      {/*
      <RegionSelect
        defaultValue={localParams.region}
        style={{ margin: '15px' }}
        setSelectedRegion={(value) =>
          setLocalParams({ ...localParams, region: value === undefined ? '' : value })
        }
      />*/}
      {/*
      <DepartmentsSelect
        defaultValue={localParams.department}
        style={{ margin: '15px' }}
        setSelectedDepartments={(value) =>
          setLocalParams({ ...localParams, department: value === undefined ? '' : value })
        }
      />*/}
    </Container>
  )
}

export default Filter
