import React from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import CheckoutForm from './CheckoutForm'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || "")

interface Props {
  price: number
  credits?: number
  successCallback: () => void
}
const Checkout = ({ price, credits, successCallback }: Props) => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm price={price} credits={credits} successCallback={successCallback} />
    </Elements>
  )
}

export default Checkout
